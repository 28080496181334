import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Button, ButtonGroup, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import React, {forwardRef} from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

export const CustomizedPaper = styled(Box)(({theme}) => ({
    width: '90%',
    backgroundColor: "#fff",
    borderRadius: '1em',
    padding: '1.3em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}))

export const CustomizedInternPaper = styled(Box)`
    padding: 1rem;
    width: 100%;
    border: medium solid rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    margin-top: 1rem;
`


export const PartTitle = (props: any) => {
    return <Typography {...props} variant="h4"/>
}


export const SettingsContainer = forwardRef((props: any, ref) => {
    return <Stack {...props} ref={ref} sx={{width: '100%'}} direction="column" justifyContent="center"
                  alignItems="center" spacing={3}/>
})


export const InternPartTitle = (props: any) => {
    return <Typography {...props} variant="h6"/>
}

export const SettingsDescription = (props: any) => {
    return <Typography sx={{margin: 0}} {...props} variant="subtitle2"/>
}

export const SettingsDescriptionWithMarginTop = (props: any) => {
    return <SettingsDescription {...props} sx={{marginTop: '.3rem'}}/>
}

export const NextButton = (props: any) => {
    return <Button variant="contained" {...props} endIcon={<ArrowCircleRightOutlinedIcon/>}/>
}

export const DownloadButton = (props: any) => {
    return <Button variant="contained" {...props} endIcon={<DownloadOutlinedIcon/>}/>
}

export const DeleteButton = (props: any) => {
    return <Button variant="contained" {...props} endIcon={<DeleteOutlinedIcon/>}/>
}

export const SettingsButtonGroup = (props: any) => {
    return <ButtonGroup sx={{marginTop: '.3rem'}} {...props} variant="contained"/>
}

export const PartDivider = styled(Divider)({
    margin: '1em 0 1em 0',
    borderBottomWidth: 'medium',
})

export const InternPartDivider = styled(Divider)({
    margin: '1em 0'
})

export const SettingsCheckbox = styled(FormControlLabel)({
    backgroundColor: '#eee',
    borderRadius: '4px',
    display: "flex",
    padding: "9px 9px 9px 0",
    margin: "0 0 .4em",
    '& .MuiFormControlLabel-label': {
        display: "inline-block"
    }
})

export const SettingsSwitch = styled(FormControlLabel)({
    backgroundColor: '#eee',
    borderRadius: '1rem',
    display: "flex",
    margin: 0,
    padding: "9px 9px 9px 0",
    '& .MuiFormControlLabel-label': {
        display: "inline-block"
    }
})


export const SettingsInternalCheckbox = (props:any) => {
    return <Checkbox {...props} color={'secondary'}/>
}

export const TemplateEditField = styled(TextField)({
    width: '100%',
    '& .MuiInputBase-root': {
        padding: '12px 8px'
    },
    '& .MuiInputBase-root input': {
        padding: '0'
    }
})

export const DefectSyncTextField = styled(TextField)({
    width: '100%',
    '& .MuiInputBase-root': {
        padding: '16.5px 8px'
    },
    '& .MuiInputBase-root input': {
        padding: '0'
    }
})

export const AdvancedSettingsButton = (props: any) => {
    return <Typography {...props} variant={"h6"} sx={{color:'darkgray', textShadow:'none', fontStyle:'italic', cursor: 'pointer'}}/>
}