import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import {
    getTranslationsFailed,
    getUsingReducedDefectList,
    registerAppStateCallback, setTranslationsFailed, setUsingReducedDefectList
} from "../utils/backboneCommunicationUtils";


interface AppStateType {
    translationsFailed: boolean | null,
    usingReducedDefectList: boolean | null
}

interface AppStateContextType {
    appState: AppStateType;
    setAppState: React.Dispatch<React.SetStateAction<AppStateType>>;
}

const appStateContextUndefined: AppStateContextType = {
    appState: {
        translationsFailed: null,
        usingReducedDefectList: null
    },
    setAppState: (value) => {}
}

interface AppStateProviderProps {
    children?: ReactNode
}


const dataCollector = (): AppStateType => {
    const result: AppStateType = {
        translationsFailed: getTranslationsFailed(),
        usingReducedDefectList: getUsingReducedDefectList()
    }

    return result;
}

export const AppStateContext = createContext<AppStateContextType>(appStateContextUndefined);

export function useAppState(): AppStateContextType {
    return useContext(AppStateContext)
}

export const AppStateProvider = ({ children }: AppStateProviderProps) => {

    const [appState, setAppState] = useState(dataCollector())

    const backboneCallback = () => {
        setAppState(dataCollector());
    }
    registerAppStateCallback(backboneCallback);

    useEffect(() => {
        if (appState.translationsFailed !== null) {
            setTranslationsFailed(appState.translationsFailed);
        }
        if (appState.usingReducedDefectList !== null) {
            setUsingReducedDefectList(appState.usingReducedDefectList);
        }
    }, [appState]); // Dependency array with contextValue

    return (
        <AppStateContext.Provider value={{ appState, setAppState }}>
            {children}
        </AppStateContext.Provider>
    );
};