import React from 'react';
import {CustomizedDialog} from '../../../components/muiCustomizedComponents';
import {translate} from '../../../utils/utils';
import {Box, Button, DialogActions, DialogContent, List, ListItem, Typography} from '@mui/material';
import {InternPartDivider} from "../SettingsComponents";

type DocumentGenerationCommentsConfigurationHelpProps = {
    open: boolean;
    onClose: () => void;
};

const documentTypes = {
    subcontractorComment: [
        'externalInspection',
        'warrantyFinished',
        'warrantyNotice',
        'warrantyReject',
        'warrantySendStatus',
        'rejectLetter',
        'principalNotification'
    ],
    principalComment: [
        'subcontractorRequest',
        'internalInspection',
        'changeNotification'
    ]
};

const renderDocumentTypes = (commentTypeKeys: string[]) => (
    <List sx={{listStyleType: 'disc', pl: 4}}>
        {commentTypeKeys.map(key => (
            <ListItem key={key} sx={{display: 'list-item', p: 0}}>
                {translate(`settings.documentGenerationConfiguration.suppressComments.type.${key}`)}
            </ListItem>
        ))}
    </List>
);


const CommentSection = ({titleKey, roleHelpKey, commentTypeKeys}: {
    titleKey: string;
    roleHelpKey: string;
    commentTypeKeys: string[];
}) => (
    <>
        <Typography>
            <strong>{translate(titleKey)} </strong>
            {translate('settings.documentGenerationConfiguration.shouldSuppressComments.help.1')}
        </Typography>
        <List sx={{listStyleType: 'disc', pl: 2}}>
            <ListItem sx={{display: 'list-item', p: 0}}>
                {translate('settings.documentGenerationConfiguration.shouldSuppressComments.help.2')}
            </ListItem>

            {renderDocumentTypes(commentTypeKeys)}

            <ListItem sx={{display: 'list-item', p: 0}}>
                {translate(roleHelpKey)}
            </ListItem>
        </List>
    </>
);


const DocumentGenerationCommentsConfigurationHelp = ({
                                                         open,
                                                         onClose
                                                     }: DocumentGenerationCommentsConfigurationHelpProps) => (
    <CustomizedDialog open={open} onClose={onClose}>
        <DialogContent>
            <Box
                sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    padding: '16px',
                    backgroundColor: '#f9f9f9',
                    margin: '16px auto',
                }}
            >
                <CommentSection
                    titleKey='settings.documentGenerationConfiguration.suppressComments.type.subcontractorComment'
                    roleHelpKey='settings.documentGenerationConfiguration.shouldSuppressComments.help.principalRole'
                    commentTypeKeys={documentTypes.subcontractorComment}
                />

                <InternPartDivider/>

                <CommentSection
                    titleKey='settings.documentGenerationConfiguration.suppressComments.type.principalComment'
                    roleHelpKey='settings.documentGenerationConfiguration.shouldSuppressComments.help.subcontractorRole'
                    commentTypeKeys={documentTypes.principalComment}
                />

                <InternPartDivider/>

                <Typography variant='body2'>
                    {translate('settings.documentGenerationConfiguration.shouldSuppressComments.help.hint')}
                </Typography>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>{translate('close')}</Button>
        </DialogActions>
    </CustomizedDialog>
);

export default DocumentGenerationCommentsConfigurationHelp;
