import {useMemo} from "react";
import {DocumentGenerationConfiguration} from "../types/types";

const useCommentCheckedSetting = (
    newProjectSettings: { documentGenerationConfiguration: DocumentGenerationConfiguration }
) => {
    const shouldSuppressCommentsChecked = useMemo(() => {
        return newProjectSettings.documentGenerationConfiguration?.shouldSuppressComments || false;
    }, [newProjectSettings]);

    const shouldSuppressSubcontractorCommentsChecked = useMemo(() => {
        return newProjectSettings.documentGenerationConfiguration?.shouldSuppressSubcontractorComments || false;
    }, [newProjectSettings]);

    return [
        shouldSuppressCommentsChecked,
        shouldSuppressSubcontractorCommentsChecked
    ];
};

export default useCommentCheckedSetting;
