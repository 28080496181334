import React, {useCallback} from 'react';
import {setErrorDialogWithCloseCallback, translate} from "../../../../utils/utils";

export const useCostThresholdInput = (
    onChange: (value: string | null) => void,
) => {
    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = e.target.value;

        const normalizedValue = inputValue && inputValue.length > 0 ? inputValue.replace(',', '.') : null;

        if (normalizedValue === null || !isNaN(Number(normalizedValue))) {
            onChange(normalizedValue);
        } else {
            setErrorDialogWithCloseCallback(translate("settings.MaxDefectCostAlertThreshold.invalidInput.alert"));
        }
    }, [onChange]);

    return {handleInputChange};
};
