import PageContainer, {setDialog, simpleChoiceDialogPromise} from "../../components/PageContainer";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FilledInput,
    FormControl,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    ListSubheader,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import {
    awaitWrap,
    downloadWrapper,
    getCustomerProjectWithPrefix,
    isUserAdmin,
    isUserPrincipal,
    isUserProjectAdmin, isUserRestrictedSubcontractor,
    isUserSubcontractor,
    openAnotherTab,
    saveLastSavedScrollTop,
    setErrorDialogWithCloseCallback,
    setSuccessDialogWithCloseCallback,
    textNodesUnder,
    translate
} from "../../utils/utils";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas';
import {StyledButton} from "@bau/material";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../../store/slices/commonStoreSlice";
// @ts-ignore
import _ from "lodash";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import {additionalInfo, page} from "../../utils/constants";
import {isWebApp} from "../../utils/appCommunicationUtils";
import {
    addContextPathPrefix,
    deleteShareTokens,
    getAdminProject,
     saveProjectSettings, saveUserSettings} from "../../service/apis";
import axios from "axios";
import {dispatchDataWithCallback, getBackboneModel} from "../../utils/backboneCommunicationUtils";
import {useNavigate} from "react-router-dom";
import {
    CostType,
    DEFECT_SYNC_DIRECTION,
    DOCUMENT_TYPE,
    FILENAME_SCHEMA,
    Language,
    PrintMode,
    SubcontractorOrder,
    UploadSetting,
    USER_AUTHORITIES
} from "./SettingsEnums";
import {
    AdvancedSettingsButton,
    CustomizedInternPaper,
    CustomizedPaper,
    DefectSyncTextField,
    DownloadButton,
    DeleteButton,
    InternPartDivider,
    InternPartTitle,
    NextButton,
    PartDivider,
    PartTitle,
    SettingsButtonGroup,
    SettingsCheckbox,
    SettingsContainer,
    SettingsDescription,
    SettingsDescriptionWithMarginTop,
    SettingsInternalCheckbox,
    SettingsSwitch,
    TemplateEditField
} from "./SettingsComponents";
import {CustomizedDialog, FlexContainer,} from "../../components/muiCustomizedComponents";
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EmailCustomizer from "./EmailCustomizer/EmailCustomizer";
import CostThresholdSetter from "./Cost/CostThresholdSetter";
import DocumentGenerationCommentsConfiguration from "./DocumentsGeneration/DocumentGenerationCommentsConfiguration";


const Settings = () => {

    const pageRef = useRef();
    const wholeSettingsRef = useRef();
    const signatureRef = useRef();
    const searchableItemsRef = useRef(new Map());

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    const userSettings = user.settings;
    const admin = isUserAdmin(user);
    const projectAdmin = isUserProjectAdmin(user);
    const subcontractorUser = isUserSubcontractor(user);
    const restrictedSubcontractorUser = isUserRestrictedSubcontractor(user);
    const principalUser = isUserPrincipal(user);
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const {customerProject} = getCustomerProjectWithPrefix();
    const subcontractors = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.subcontractors]);

    const [noSettingsMatch, setNoSettingsMatch] = useState<boolean>(false);
    const [newUserSettings, setNewUserSettings] = useState<any>(userSettings);
    const [newProjectSettings, setNewProjectSettings] = useState<any>(currentProject);
    const [userSettingsChanged, setUserSettingsChanged] = useState<boolean>(false);
    const [projectSettingsChanged, setProjectSettingsChanged] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");


    const [initialTemplate, setInitialTemplate] = useState<any>(null);
    const [template, setTemplate] = useState<any>(null);
    const [templateChanged, setTemplateChanged] = useState<boolean>(false);
    const [saveTemplateValidationCode, setSaveTemplateValidationCode] = useState<string>('');
    const [userTippedSaveTemplateValidationCode, setUserTippedSaveTemplateValidationCode] = useState<string>('');

    const [selectedStructureKey, setSelectedStructureKey] = useState<string>(((currentProject && currentProject.structureKeys.length > 0) ? currentProject.structureKeys[0] : ""));
    const [projectDataSyncString, setProjectDataSyncString] = useState<string>("");
    const [savedProjectDataSyncString, setSavedProjectDataSyncString] = useState<string>("");

    const [showHelpCode, setShowHelpCode] = useState<boolean>(false);
    const [userTippedHelpCode, setUserTippedHelpCode] = useState<string>("");

    const getDocumentGenerationOptions = () => {
        let res = [];
        res.push({divider: true, text: translate("settings.request")});
        _.forEach(currentProject.types, (type:any) => {
            let typeConfig = currentProject.typeConfiguration[type.defectTypeId];
            let requests = 0;
            let terminationLevel = false;
            if (typeConfig.firstEscalationLevel) {
                requests = 1;
            }
            if (typeConfig.secondEscalationLevel) {
                requests = 2;
            }
            if (typeConfig.thirdEscalationLevel) {
                requests = 3;
            }
            if (typeConfig.terminationLevel) {
                terminationLevel = true;
            }

            if (user && user.customer === 'schuett') {
                if (type.defectTypeId === 'deadline' || type.defectTypeId === 'obstruction') {
                    requests = 3;
                }
            }
            if (user && user.customer === 'kriegerschramm') {
                if (type.defectTypeId === 'unfinished' || type.defectTypeId === 'additional' || type.defectTypeId === 'followup') {
                    requests = 3;
                }
            }

            if (requests > 1) {
                for (let i = 1; i <= requests; i++) {
                    let requestLabel = i === 1 ? translate('settings.firstrequest') : i === 2 ? translate('settings.secondrequest') : translate('settings.thirdrequest');
                    let itemLabel = `${translate('settings.preview')} ${translate(type.label)} ${requestLabel}`;

                    let ignore = false;

                    if (user && user.customer === 'nething') {
                        if (type.defectTypeId === 'technical') {
                            if (i <= 2) {
                                itemLabel = 'Vorschau Mangelanzeige ' + (i === 2 ? 'Nachfrist ' : '') + 'BM';
                            } else {
                                ignore = true;
                            }
                        } else if (type.defectTypeId === 'safety') {
                            itemLabel = 'Vorschau Arbeitseinstellung wg. Sicherheit AS';
                            if (i > 1) {
                                ignore = true;
                            }
                        } else if (type.defectTypeId === 'waste') {
                            itemLabel = 'Vorschau Ordnung auf der Baustelle OR';
                            if (i > 1) {
                                ignore = true;
                            }
                        } else if (type.defectTypeId === 'warranty') {
                            if (i <= 2) {
                                itemLabel = 'Vorschau Gewährleistungsmängel ' + (i === 2 ? 'Nachfrist ' : '') + 'GW';
                            } else {
                                ignore = true;
                            }
                        }
                    }

                    if (!ignore) {
                        res.push({documentType: DOCUMENT_TYPE.REQUEST_PREVIEW,  attributes:{type:type.defectTypeId, request:i}, text:itemLabel});
                    }
                }
            } else {
                let itemLabel = `${translate('settings.preview')}${translate(type.label)}`;

                if (user && user.customer === 'nething') {
                    if (type.defectTypeId === 'deadline') {
                        itemLabel = 'Vorschau Terminverzug VZ';
                    }
                }

                res.push({documentType: DOCUMENT_TYPE.REQUEST_PREVIEW, attributes:{type: type.defectTypeId, request:1}, text:itemLabel});
            }
            if (terminationLevel) {
                let itemLabel = `${translate('settings.preview')} ${translate(type.label)} ${translate('settings.terminationrequest')}(${typeConfig.terminationLevelName})`;
                res.push({documentType: DOCUMENT_TYPE.REQUEST_PREVIEW, attributes:{type: type.defectTypeId, request:4}, text:itemLabel});
            }
        });

        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{type: 'REJECT'}, text: `${translate("settings.preview")} ${translate("filename.reject")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'NOTIFY'}, text: `${translate("settings.preview")} ${translate("settings.notify")}`});

        res.push({divider: true, text:translate("settings.singlepreview")});

        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'GW_NOTICE'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate('filename.gw.notice')}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'GW_NOTICE_GOODWILL'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.gw.noticegoodwill")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'GW_SENDSTATUS'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.gw.sendstatus")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'GW_FINISHED'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.gw.finished")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'GW_REJECT'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.gw.reject")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'AN_REJECT_OBJECTION'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.subcontractor.rejectobjection")}`});
        res.push({documentType: DOCUMENT_TYPE.SINGLE_PREVIEW, attributes:{ type: 'AN_REJECT_OBJECTION_WARRANTY'}, text: `${translate("settings.preview")} ${translate("defect.type.warranty")} ${translate("filename.subcontractor.rejectobjectionwarranty")}`});

        if (user.customer !== 'nething') {
            res.push({divider: true, text:translate("settings.protocolpreview")});
            user.inspections.forEach((inspection:any) => {
                res.push({documentType: DOCUMENT_TYPE.PROTOCOL_PREVIEW, attributes:{inspection: inspection.id}, text: `${translate("settings.preview")} ${translate("settings.preview.protocol")} ${inspection.name}`});
            })
        }
        return res;
    }

    const [documentGenerationOptions, setDocumentGenerationOptions] = useState<any[]>(getDocumentGenerationOptions);
    const [selectedDocumentGenerationOptionText, setSelectedDocumentGenerationOptionText] = useState<string>(documentGenerationOptions[1].text);

    const getProjectDefectSyncDirection = () => {
        if(subcontractors.some((subcontractor:any) => !!subcontractor.syncTargetCustomer || !!subcontractor.syncTargetProject)) {
            let subcontractorWithDefectSync = subcontractors.filter((subcontractor:any) => !!subcontractor.syncTargetCustomer || !!subcontractor.syncTargetProject);
            let defectSyncToSubcontractorData = subcontractorWithDefectSync.map((s:any) => {
                return {
                    subcontractorId: s.id, defectSyncText: `${s.syncTargetCustomer}:${s.syncTargetProject}`
                }
            })
            return {
                direction: DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR,
                defectSyncToSubcontractorData: defectSyncToSubcontractorData,
                defectSyncToPrincipalData: ""
            };
        }
        return {
            direction: DEFECT_SYNC_DIRECTION.TO_PRINCIPAL,
            defectSyncToSubcontractorData: [],
            defectSyncToPrincipalData: (!!currentProject.syncSourceCustomer || !!currentProject.syncSourceProject) ? `${currentProject.syncSourceCustomer || ""}:${currentProject.syncSourceProject || ""}` : ""
        };
    }

    const [initialDefectSyncData, setInitialDefectSyncData] = useState<any>(getProjectDefectSyncDirection);
    const [defectSyncDirection, setDefectSyncDirection] = useState<DEFECT_SYNC_DIRECTION>(initialDefectSyncData.direction);
    const [defectSyncToSubcontractorData, setDefectSyncToSubcontractorData] = useState<{subcontractorId:string, defectSyncText:string}[]>(initialDefectSyncData.defectSyncToSubcontractorData);
    const [defectSyncToPrincipalData, setDefectSyncToPrincipalData] = useState<string>(initialDefectSyncData.defectSyncToPrincipalData);
    const [defectSyncSettingsChanged, setDefectSyncSettingsChanged] = useState<boolean>(false);


    const [showMoreSettingsWhenSearch, setShowMoreSettingsWhenSearch] = useState<boolean>(false);
    const [showMoreUserSettings, setShowMoreUserSettings] = useState<boolean>(true);
    const [showMoreProjectSettings, setShowMoreProjectSettings] = useState<boolean>(true);


    //--------------user settings---------------------

    const updateUserSettings = (changedSettings: any) => {
        let mergedSettings = _.merge(_.cloneDeep(newUserSettings), changedSettings);
        setUserSettingsChanged(!_.isEqual(userSettings, mergedSettings));
        setNewUserSettings(mergedSettings);
    }
    useEffect(() => {
        if (!!signatureRef.current) {
            // @ts-ignore
            signatureRef.current.clear();
            if(!!newUserSettings.signature) {
                // @ts-ignore
                signatureRef.current.fromDataURL(newUserSettings.signature, {width: 600, height: 300});
            }
        }
    })
    const drawSignatureEnd = () => {
        // @ts-ignore
        updateUserSettings({signature: !!signatureRef.current && signatureRef.current.toDataURL('image/png')});
    }

    const clearSignature = () => {
        updateUserSettings({signature: null});
    }

    //-----------------------project settings--------------------------

    const updateProjectSettings = (changedSettings: any) => {
        let mergedSettings = _.merge(_.cloneDeep(newProjectSettings), changedSettings);
        setProjectSettingsChanged(!_.isEqual(currentProject, mergedSettings));
        setNewProjectSettings(mergedSettings);
    }
    
    const getDocumentGenerationOption = ()=>{
        let res = documentGenerationOptions.find(el => el.text === selectedDocumentGenerationOptionText);
        if(!res) {
            setDialog({
                title: translate("error"),
                text: translate("internalerror"),
                buttons: [
                    {
                        label: "OK",
                        callback: ()=>setDialog(null)
                    }
                ]
            })
        }
        return res;
    }


    const getParamsWithAttributes = (attributes: any) => {
        return `${_.join(_.map(_.toPairs(attributes), (pair: any) => _.join(pair, '=')), '&')}&project=${currentProject.id}&${+ new Date()}`;
    }


    const downloadPreview = () => {
        let selectedDocumentGenerationOption = getDocumentGenerationOption();
        if(!selectedDocumentGenerationOption) {
            return;
        }
        let params = getParamsWithAttributes(selectedDocumentGenerationOption.attributes);
        openAnotherTab(`${DOCUMENT_TYPE.getDownloadUrlPrefix(selectedDocumentGenerationOption.documentType)}${params}`, translate('download'))
    }

    const downloadAll = () => {
        let protocols = documentGenerationOptions.filter(el => el.documentType === DOCUMENT_TYPE.PROTOCOL_PREVIEW).map(el => el.attributes.inspection);
        let singles = documentGenerationOptions.filter(el => el.documentType === DOCUMENT_TYPE.SINGLE_PREVIEW).map(el => el.attributes.type);
        let requests1 = documentGenerationOptions.filter(el => el.documentType === DOCUMENT_TYPE.REQUEST_PREVIEW && el.attributes.request === 1).map(el => el.attributes.type);
        let requests2 = documentGenerationOptions.filter(el => el.documentType === DOCUMENT_TYPE.REQUEST_PREVIEW && el.attributes.request === 2).map(el => el.attributes.type);
        let requests3 = documentGenerationOptions.filter(el => el.documentType === DOCUMENT_TYPE.REQUEST_PREVIEW && el.attributes.request === 3).map(el => el.attributes.type);

        let params = `project=${currentProject.id}&protocols%5B%5D=${protocols.join("&protocols%5B%5D=")}&singles%5B%5D=${singles.join("&singles%5B%5D=")}&requests1%5B%5D=${requests1.join("&requests1%5B%5D=")}&requests2%5B%5D=${requests2.join("&requests2%5B%5D=")}&requests3%5B%5D=${requests3.join("&requests3%5B%5D=")}&${+ new Date()}`;
        openAnotherTab(`/api/downloadAllPreview?${params}`, translate('download'));
    }

    const showPreview = async ()=>{
        let selectedDocumentGenerationOption = getDocumentGenerationOption();
        if(!selectedDocumentGenerationOption) {
            return;
        }
        let url = addContextPathPrefix(DOCUMENT_TYPE.getPreviewUrl(selectedDocumentGenerationOption.documentType));
        let [e,r]= await downloadWrapper(()=>axios.postForm(url, _.merge(selectedDocumentGenerationOption.attributes, {project: currentProject.id})));
        if(!!r) {
            let data = r.data;
            let ProjectFile = getBackboneModel('ProjectFile');
            // @ts-ignore
            window.imagePage.setModel(new ProjectFile(data));
            // @ts-ignore
            window.imagePage.setupPageForDocument();
            navigate(`${customerProject}/image`, {state:{pageId: 'settings'}});
        }
    }

    const changeDocumentTemplate = async () => {
        let selectedDocumentGenerationOption = getDocumentGenerationOption();
        if(!selectedDocumentGenerationOption) {
            return;
        }
        if(selectedDocumentGenerationOption.documentType !== DOCUMENT_TYPE.REQUEST_PREVIEW && selectedDocumentGenerationOption.documentType !== DOCUMENT_TYPE.SINGLE_PREVIEW && selectedDocumentGenerationOption.documentType !== DOCUMENT_TYPE.PROTOCOL_PREVIEW) {
            setErrorDialogWithCloseCallback(translate("requestbundle.noChangeInspections"));
            return;
        }
        let url = addContextPathPrefix(DOCUMENT_TYPE.getTemplateEditUrl(selectedDocumentGenerationOption.documentType, currentProject.id, selectedDocumentGenerationOption.attributes));
        let [e,r] = await downloadWrapper(()=>axios.get(url));
        if(!!r && r.data) {
            let data = r.data;
            if(_.isEmpty(data.fileMap)) {
                setErrorDialogWithCloseCallback(translate("settings.noEditableText"));
                return;
            }
            setInitialTemplate(data);
            setTemplate(data);
        }
    }

    const updateTemplate = (changedTemplate: any)=>{
        let mergedTemplate = _.merge(_.cloneDeep(template), changedTemplate);
        setTemplateChanged(!_.isEqual(mergedTemplate, initialTemplate));
        setTemplate(mergedTemplate);
    }

    const closeTemplateEditDialog = () => {
        setInitialTemplate(null);
        setTemplate(null);
        setTemplateChanged(false);
    }

    const saveTemplateClicked = ()=>{
        let timestamp = `${+ new Date()}`;
        let validationCode = timestamp.substring(timestamp.length - 6);
        setSaveTemplateValidationCode(validationCode);
    }

    const closeSaveTemplateValidationDialog = ()=>{
        setUserTippedSaveTemplateValidationCode('');
        setSaveTemplateValidationCode('');
    }

    const closeHelpCodeDialog = ()=>{
        setShowHelpCode(false);
        setUserTippedHelpCode("");
    }

    const validateUserTippedHelpCode = ()=>{
        if(userTippedHelpCode === "QT") {
            closeHelpCodeDialog();
            navigate("/queueTool");
            return;
        }
        setShowHelpCode(false);
        setDialog({
            title: translate("error"),
            text: translate("settings.helpcodeinvalid"),
            buttons: [
                {
                    label: translate("ok"),
                    callback: ()=>{
                        setDialog(null);
                        setShowHelpCode(true);
                    }
                }
            ]
        });
    }

    const saveNewTemplate = async (projectFolder: boolean)=>{
        closeSaveTemplateValidationDialog();
        closeTemplateEditDialog();
        let json = template.fileMap;
        let selectedOption = getDocumentGenerationOption();
        let params = Object.values(selectedOption.attributes).join('/');
        let url = addContextPathPrefix(`/api/saveFopToTemplate/${currentProject.id}/${params}/${projectFolder}`);
        dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: true}));
        let [e, r] = await awaitWrap(axios.post(url, json));
        dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: false}));
        if(!!e) {
            setErrorDialogWithCloseCallback(translate("requestbundle.error"));
        } else {
            setSuccessDialogWithCloseCallback(translate("requesetbundle.savesuccess"))
        }
    }

    const createCodes = (addLogo: boolean) => {
        let selectedStructureKeyIndex:number = currentProject.structureKeys.findIndex((key:string) => key === selectedStructureKey);
        openAnotherTab(`/api/codeDocument/${currentProject.id}?level=${selectedStructureKeyIndex + 1}&addLogo=${addLogo}`, translate("download"));
    }

    const downloadUnits = ()=>{
        openAnotherTab(`/api/codes/${currentProject.id}`, translate("download"));
    }

    const openDisableShareTokensDialog = () => {
        setDialog({
            title: translate("clear"),
            text: translate("settings.requestQrCodeShare.delete.popup.info"),
            buttons: [
                {
                    label: translate("popup.no"),
                    callback: ()=> setDialog(null)
                },
                {
                    label: translate("popup.yes"),
                    callback: async () => {
                        setDialog(null);
                        dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: true}));
                        let [e,] = await awaitWrap(deleteShareTokens(currentProject.id));
                        dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: false}));

                        if (!!e) {
                            setErrorDialogWithCloseCallback(translate("settings.requestQrCodeShare.delete.popup.error"));
                            return;
                        } else {
                            setSuccessDialogWithCloseCallback(translate("settings.requestQrCodeShare.delete.popup.success"));
                        }
                    }
                }
            ]
        });
    }

    const updateDefectSyncToSubcontractorData = (oldSubcontractorId?:string, newSubcontractorId?:string, text?:string) => {
        let newData = _.cloneDeep(defectSyncToSubcontractorData);
        if(!!oldSubcontractorId && !!newSubcontractorId && oldSubcontractorId === newSubcontractorId) {
            let data = newData.find((s:any) => s.subcontractorId === oldSubcontractorId);
            data.defectSyncText = text;
        } else {
            newData = newData.filter((s:any) => s.subcontractorId !== oldSubcontractorId);
            if(!!newSubcontractorId) {
                newData.push({subcontractorId: newSubcontractorId, defectSyncText:""});
            } else {
                if(!oldSubcontractorId) {
                    let notSelectedSubcontractors = subcontractors.filter((s:any) => newData.every((data:any) => data.subcontractorId !== s.id));
                    if(notSelectedSubcontractors.length > 0) {
                        newData.push({subcontractorId: notSelectedSubcontractors[0].id, defectSyncText:""});
                    }
                }
            }
        }
        setDefectSyncToSubcontractorData(newData);
    }

    useEffect(()=>{
        let newDefectSyncData = {
            direction: defectSyncDirection,
            defectSyncToSubcontractorData,
            defectSyncToPrincipalData
        }
        setDefectSyncSettingsChanged(!_.isEqual(initialDefectSyncData, newDefectSyncData));
    },[defectSyncToSubcontractorData, defectSyncDirection, defectSyncToPrincipalData])

    //----------------------------common use------------------------------------


    const resetSettings = () => {
        setIsResetTriggered(false);
        setDialog({
            title: translate("defects.menu.external.warning.header"),
            text: translate("settings.sureresetsettings"),
            buttons: [
                {
                    label: translate("inspection.archive.changestatus.no"),
                    callback: ()=>setDialog(null)
                },
                {
                    label: translate("inspection.archive.changestatus.yes"),
                    callback: ()=>{
                        setDialog(null);
                        updateUserSettings(userSettings);
                        updateProjectSettings(currentProject);
                        setDefectSyncDirection(initialDefectSyncData.direction);
                        setDefectSyncToPrincipalData(initialDefectSyncData.defectSyncToPrincipalData);
                        setDefectSyncToSubcontractorData(initialDefectSyncData.defectSyncToSubcontractorData);
                        setIsResetTriggered(true);
                    }
                }
            ]
        })
    }

    const getDefectSyncString = () => {
        if(defectSyncDirection === DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR) {
            return defectSyncToSubcontractorData.map(data => data.defectSyncText).join(",");
        }
        return defectSyncToPrincipalData;
    }

    const validateInputs = () => {
        if(!!projectDataSyncString) {
            try {
                JSON.parse(projectDataSyncString);
            } catch (e) {
                return {valid: false, errorMsg: translate("settings.notjsonformat")};
            }
        }
        return {valid: true, errorMsg: ''};
    }

    const saveSettings = async () => {
        let validateRes = validateInputs();
        if(!validateRes.valid) {
            setErrorDialogWithCloseCallback(validateRes.errorMsg);
            return false;
        }
        let needReload = newUserSettings.lang !== userSettings.lang || newUserSettings.subcontractorOrder !== userSettings.subcontractorOrder;
        if(needReload) {
            let [e, r] = await awaitWrap(simpleChoiceDialogPromise(translate("warn"), translate("settings.saveneedreload")));
            if(!!e) {
                return false;
            }
        }
        dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: true}));
        if((projectSettingsChanged || defectSyncSettingsChanged) && !userSettingsChanged) {
            let projectSettingsData = newProjectSettings;
            if(defectSyncSettingsChanged) {
                projectSettingsData = _.cloneDeep(newProjectSettings);
                projectSettingsData.defectSync = getDefectSyncString();
            }
            let [e1,r1] = await awaitWrap(saveProjectSettings(currentProject.id, projectSettingsData));
            dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: false}));
            if(!!e1) {
                setErrorDialogWithCloseCallback(translate("settings.savesettingsfailed"));
                return false;
            }
            dispatchDataWithCallback(true, CommonStoreStateKeys.currentProject, projectSettingsData, dataFromBackbone => {
                dispatch(setStateValue({key:CommonStoreStateKeys.currentProject, value: dataFromBackbone}));
            })
        } else if(!projectSettingsChanged && !defectSyncSettingsChanged && userSettingsChanged) {
            let [e1,r1] = await awaitWrap(saveUserSettings(newUserSettings));
            dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: false}));
            if(!!e1) {
                setErrorDialogWithCloseCallback(translate("settings.savesettingsfailed"));
                return false;
            }
            if(needReload) {
                dispatch(setStateValue({key:CommonStoreStateKeys.currentCustomer, value:""}));
                navigate('/');
                return false;
            } else {
                dispatchDataWithCallback(true, CommonStoreStateKeys.user, r1?.data, dataFromBackbone => {
                    dispatch(setStateValue({key:CommonStoreStateKeys.user, value: dataFromBackbone}));
                })
            }
        } else {
            let projectSettingsData = newProjectSettings;
            if(defectSyncSettingsChanged) {
                projectSettingsData = _.cloneDeep(newProjectSettings);
                projectSettingsData.defectSync = getDefectSyncString();
            }
            let [e1,r1] = await awaitWrap(saveProjectSettings(currentProject.id, projectSettingsData));
            let [e2,r2] = await awaitWrap(saveUserSettings(newUserSettings));
            dispatch(setStateValue({key: CommonStoreStateKeys.loading, value: false}));
            if(!!e1 || !!e2) {
                setErrorDialogWithCloseCallback(translate("settings.savesettingsfailed"));
                return false;
            }
            if(needReload) {
                dispatch(setStateValue({key:CommonStoreStateKeys.currentCustomer, value:""}));
                navigate('/');
                return false;
            } else {
                dispatchDataWithCallback(true, CommonStoreStateKeys.currentProject, projectSettingsData, dataFromBackbone => {
                    dispatch(setStateValue({key:CommonStoreStateKeys.currentProject, value: dataFromBackbone}));
                })
                dispatchDataWithCallback(true, CommonStoreStateKeys.user, r2?.data, dataFromBackbone => {
                    dispatch(setStateValue({key:CommonStoreStateKeys.user, value: dataFromBackbone}));
                })
            }
        }
        setUserSettingsChanged(false);
        setProjectSettingsChanged(false);
        setDefectSyncSettingsChanged(false);
        await simpleChoiceDialogPromise(translate("hint"), translate("savechangessuccessfully"), true);
        return true;
    }

    const right = [
        <StyledButton key="save" onClick={saveSettings}>{translate("save")}</StyledButton>,
        <StyledButton key="reset" onClick={resetSettings}>{translate("reset")}</StyledButton>
    ]

    const [isResetTriggered, setIsResetTriggered] = useState<boolean>(false);

    const onScroll = (top: number) => {
        saveLastSavedScrollTop({top, scrolledPage: page.SETTINGS});
    }

    useEffect(() => {
        setShowMoreSettingsWhenSearch(!!searchValue);
        let searchableItems = searchableItemsRef.current;
        // @ts-ignore
        if (!!searchableItems && searchableItems.size > 0) {
            searchableItems.forEach((node) => {
                if (!!node) {
                    let texts = textNodesUnder(node, searchValue);
                    texts.push(node.getAttribute('aria-details'));
                    let match = texts.some(t => !!t && t.toUpperCase().includes(searchValue.toUpperCase()));
                    if (!node.hasAttribute('originDisplay')) {
                        node.setAttribute('originDisplay', node.style.display);
                    }
                    let originDisplay = node.getAttribute('originDisplay');
                    node.style.display = match ? originDisplay : 'none';
                }
            })
        }

        let noSettingsMatch = true;
        if(!!wholeSettingsRef.current) {
            // @ts-ignore
            for(let i=1; i<wholeSettingsRef.current.children.length; i++) {
                // @ts-ignore
                let childrenNodes = wholeSettingsRef.current.children[i].children;
                for(let j=0; j<childrenNodes.length; j++) {
                    let node = childrenNodes[j];
                    if(node.tagName === 'DIV' && node.style.display !== 'none' && node.getAttribute('aria-details') !== "notSearchable") {
                        noSettingsMatch = false;
                        break;
                    }
                }
                if(!noSettingsMatch) {
                    break;
                }
            }
        }
        setNoSettingsMatch(noSettingsMatch);
    }, [searchValue])

    useEffect(() => {
        (async ()=>{
            if(admin && currentProject.hasSync) {
                await getProjectDataSyncJSON(false);
            }
        })()
        setShowMoreProjectSettings(false);
        setShowMoreUserSettings(false);
    }, [])

    const setRef = (key: string, node: any) => {
        if (!!node) {
            // @ts-ignore
            searchableItemsRef.current.set(key, node);
        }
    }

    const sidePanelItemOnClick = (path:string) => {
        if(userSettingsChanged || projectSettingsChanged) {
            setDialog({
                title: translate("warn"),
                text: translate("settings.savechanges"),
                buttons: [
                    {
                        label: translate("cancel"),
                        callback: ()=>setDialog(null)
                    },
                    {
                        label: translate("no"),
                        callback: () => {
                            setDialog(null);
                            navigate(path);
                        }
                    },
                    {
                        label: translate("yes"),
                        callback: async () => {
                            setDialog(null);
                            let saveSuccess = await saveSettings();
                            if(saveSuccess) {
                                navigate(path);
                            }
                        }
                    }
                ]
            })
        } else {
            navigate(path);
        }
    }

    const getProjectDataSyncJSON = async (showErrorPopup:boolean)=>{
        let [e, r] = await awaitWrap(getAdminProject(currentProject.id));
        if(!!e && showErrorPopup) {
            setErrorDialogWithCloseCallback(translate("settings.downloadprojectsyncdatafailed"));
        } else if(!!r && !!r.data && !!r.data.sync && !savedProjectDataSyncString) {
            let savedSyncData = JSON.stringify(r.data.sync);
            setSavedProjectDataSyncString(savedSyncData);
            setProjectDataSyncString(savedSyncData);
        }
    }

    const checkUserRight = (rights:[USER_AUTHORITIES], callback:any) => {
       if( _.intersection(user.authorities, rights).length > 0) {
           callback();
       } else {
           setDialog({
               title: translate("warn"),
               text: translate("settings.userrightnotmatch") + "\n" + rights.join(", "),
               buttons: [
                   {
                       label: translate("ok"),
                       callback: () => {
                           setDialog(null);
                       }
                   }
               ]
           })
       }
    }

    return <PageContainer pageId={page.SETTINGS} ref={pageRef} sidePanelItemOnClick={sidePanelItemOnClick} onScroll={onScroll} headerText={translate("settings.title")} newPage={true}
                          right={(userSettingsChanged || projectSettingsChanged || defectSyncSettingsChanged) && right}>
        <SettingsContainer className="whole-settings-container" ref={wholeSettingsRef}>
                <CustomizedPaper>
                    <FormControl variant="filled">
                        <InputLabel>{translate('settings.search')}</InputLabel>
                        <FilledInput
                            value={searchValue}
                            onChange={event => setSearchValue(event.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon/>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </CustomizedPaper>

                <CustomizedPaper ref={(node) => setRef('userSettingsContainer', node)}>
                    <PartTitle>{translate('settings.user.title')}</PartTitle>
                    <PartDivider/>

                    <CustomizedInternPaper aria-details={translate('settings.user.title')} ref={(node) => setRef('userSettingsLangContainer', node)}>
                        <InternPartTitle>{translate('settings.language.title')}</InternPartTitle>
                        <SettingsDescription>{translate('settings.setlang')}</SettingsDescription>
                        <InternPartDivider/>
                        <FormControl aria-details={`${translate('settings.language.title')}-${translate('settings.user.title')}`} ref={(node) => setRef('userSettingsLang', node)} variant="filled">
                            <InputLabel>{translate('settings.language.title')}</InputLabel>
                            <Select
                                onChange={(e: SelectChangeEvent) => updateUserSettings({lang: e.target.value})}
                                value={newUserSettings.lang || Language.DE}
                            >
                                {[Language.DE, Language.EN, Language.PL, Language.ZH].map(lang => {
                                    return <MenuItem key={lang}
                                                     value={lang}>{Language.getLanguageDescription(lang)}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </CustomizedInternPaper>

                    {!restrictedSubcontractorUser && <CustomizedInternPaper aria-details={translate('settings.user.title')} ref={(node) => setRef('userSettingsNotificationContainer', node)}>
                        <InternPartTitle>{translate('settings.user.notifications.title')}</InternPartTitle>
                        <InternPartDivider/>

                        <Box aria-details={`${translate('settings.user.title')}-${translate('settings.user.notifications.title')}`} ref={(node) => setRef('userSettingsNotification1', node)}>
                            <SettingsSwitch control={<Switch checked={newUserSettings.sendStatisticsEmails}
                                                             onChange={e => updateUserSettings({sendStatisticsEmails: e.target.checked})}/>}
                                            label={translate('settings.sendstatisticsemails')}/>
                        </Box>

                    </CustomizedInternPaper>}
                </CustomizedPaper>

            {admin && <CustomizedPaper ref={(node) => setRef('projectSettingsContainer', node)}>
                <PartTitle>{translate("settings.project.title")}</PartTitle>
                <PartDivider/>


                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={node => setRef('projectSettingsGroupContainer', node)}>
                    <InternPartTitle>{translate("settings.groups.title")}</InternPartTitle>
                    <InternPartDivider/>

                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.groups.title")}`}
                         ref={node => setRef('projectSettingsGroupActive', node)}>
                        <SettingsSwitch control={<Switch checked={newProjectSettings.groupsActive}
                                                         onChange={e => updateProjectSettings({groupsActive: e.target.checked})}/>}
                                        label={translate('settings.groupsActive.title')}/>
                        <SettingsDescriptionWithMarginTop>{translate("settings.groupsActive.hint")}</SettingsDescriptionWithMarginTop>
                        {currentProject.groupsActive && <InternPartDivider/>}
                    </Box>

                    {currentProject.groupsActive && <Box aria-details={`${translate("settings.project.title")}-${translate("settings.groups.title")}`}
                          ref={(node: any) => setRef('projectSettingsGroupConfigure', node)}>
                        <NextButton
                            onClick={() => openAnotherTab(`/webStatic/projectmanager-groups-entry?projectId=${currentProject.id}`)}>{translate("settings.configureGroups.title")}</NextButton>
                    </Box>}
                </CustomizedInternPaper>


                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={(node) => setRef('projectSettingsNotificationContainer', node)}>
                    <InternPartTitle>{translate('settings.user.notifications.title')}</InternPartTitle>
                    <InternPartDivider/>

                    <Box
                        aria-details={`${translate("settings.project.title")}-${translate('settings.user.notifications.title')}`}
                        ref={(node) => setRef('projectSettingsNotifyChanges', node)}>
                        <SettingsSwitch control={<Switch
                            onChange={e => updateUserSettings({notifyChanges: {[currentProject.id]: {notify: e.target.checked}}})}
                            checked={(!!newUserSettings.notifyChanges[currentProject.id] && newUserSettings.notifyChanges[currentProject.id].notify) || false}/>}
                                        label={translate('settings.changes.notifychanges')}/>
                        <InternPartDivider/>
                    </Box>

                    <Box
                        aria-details={`${translate("settings.project.title")}-${translate('settings.user.notifications.title')}`}
                        ref={(node) => setRef('projectSettingsNotifyChanges2', node)}>
                        <SettingsSwitch control={<Switch
                            onChange={e => updateUserSettings({notifyChanges: {[currentProject.id]: {notify2: e.target.checked}}})}
                            checked={(!!newUserSettings.notifyChanges[currentProject.id] && newUserSettings.notifyChanges[currentProject.id].notify2) || false}/>}
                                        label={translate('settings.changes.notifychanges2')}/>
                        <InternPartDivider/>
                    </Box>


                    <Box
                        aria-details={`${translate("settings.project.title")}-${translate('settings.user.notifications.title')}`}
                        ref={(node) => setRef('projectSettingsNotifyChanges3', node)}>
                        <SettingsSwitch control={<Switch
                            onChange={e => updateUserSettings({notifyChanges: {[currentProject.id]: {notify3: e.target.checked}}})}
                            checked={(!!newUserSettings.notifyChanges[currentProject.id] && newUserSettings.notifyChanges[currentProject.id].notify3) || false}/>}
                                        label={translate('settings.changes.notifychanges3')}/>
                    </Box>

                </CustomizedInternPaper>


                {isWebApp && <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                        ref={node => setRef('projectSettingsQrcodeContainer', node)}>
                    <InternPartTitle>{translate("settings.qrcodes.title")}</InternPartTitle>
                    <SettingsDescription>{translate("settings.qrcodes.hint")}</SettingsDescription>
                    <InternPartDivider/>


                    {!!currentProject.structureKeys && (currentProject.structureKeys.length > 0) &&
                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.qrcodes.title")}`}
                         ref={node => setRef('projectSettingsQrcode1', node)}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.createCodes.title")}</InputLabel>
                            <Select
                                onChange={e => setSelectedStructureKey(e.target.value)}
                                value={selectedStructureKey}
                            >
                                {currentProject.structureKeys.map((el: any) => {
                                    return <MenuItem key={el}
                                                     value={el}>{el}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <SettingsButtonGroup sx={{marginTop: ".3rem"}}>
                            <Button onClick={() => createCodes(false)}
                                    variant="contained">{translate("settings.createcodes.withoutlogo")}</Button>
                            <Button onClick={() => createCodes(true)}
                                    variant="contained">{translate("settings.createcodes.withlogo")}</Button>
                        </SettingsButtonGroup>
                        <InternPartDivider/>
                    </Box>}


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.qrcodes.title")}`}
                         ref={(node: any) => setRef('projectSettingsQrcode2', node)}>
                        <DownloadButton
                            onClick={downloadUnits}>{translate("settings.downloadUnits.title")}</DownloadButton>
                    </Box>
                </CustomizedInternPaper>}


                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={node => setRef('projectSettingsWarrantyContainer', node)}>
                    <InternPartTitle>{translate("settings.warranty.title")}</InternPartTitle>
                    <InternPartDivider/>


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.warranty.title")}`}
                         ref={node => setRef('projectSettingsWarrantyBuyerAccess', node)}>
                        <NextButton
                            onClick={(e: any) => navigate(`${customerProject}/buyerAccess`)}>{translate("settings.tobuyeraccess")}</NextButton>
                        <SettingsDescriptionWithMarginTop>{translate("settings.buyeraccess.hint")}</SettingsDescriptionWithMarginTop>
                        <InternPartDivider/>
                    </Box>


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.warranty.title")}`}
                         ref={node => setRef('projectSettingsWarrantyDataOverview', node)}>
                        <NextButton
                            onClick={(e: any) => navigate(`${customerProject}/warrantyDataOverview`)}>{translate("settings.totopwarrantydataoverview")}</NextButton>
                        <SettingsDescriptionWithMarginTop>{translate("settings.warrantyData.hint")}</SettingsDescriptionWithMarginTop>
                        <InternPartDivider/>
                    </Box>


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.warranty.title")}`}
                         ref={node => setRef('projectSettingsWarrantyNoWarranty', node)}>
                        <SettingsSwitch control={<Switch
                            onChange={e => updateUserSettings({showWarrantyWhenNoWarranty: e.target.checked})}
                            checked={newUserSettings.showWarrantyWhenNoWarranty}/>}
                                        label={translate('settings.show.onlyWhenNoWarranty')}/>
                        <InternPartDivider/>
                    </Box>


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.warranty.title")}`}
                         ref={node => setRef('projectSettingsWarrantyPersonsOverview', node)}>
                        <NextButton
                            onClick={(e: any) => navigate(`${customerProject}/personCSV`)}>{translate("settings.topersonsoverview")}</NextButton>
                        <SettingsDescriptionWithMarginTop>{translate("settings.persons.hint")}</SettingsDescriptionWithMarginTop>
                        <InternPartDivider/>
                    </Box>


                    {!!currentProject.structureKeys && (currentProject.structureKeys.length > 0) &&
                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.warranty.title")}`}
                         ref={node => setRef('projectSettingsWarrantyContactIndex', node)}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.contactIndex.title")}</InputLabel>
                            <Select
                                onChange={e => updateProjectSettings({contactIndex: ((currentProject.structureKeys.findIndex((el: string) => el === e.target.value))+1)})}
                                value={currentProject.structureKeys[(newProjectSettings.contactIndex - 1) || 0]}
                            >
                                {currentProject.structureKeys.map((el: any) => {
                                    return <MenuItem key={el}
                                                     value={el}>{el}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>}

                </CustomizedInternPaper>


                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={node => setRef('projectSettingsCostContainer', node)}>
                    <InternPartTitle>{translate("settings.costs.title")}</InternPartTitle>
                    <SettingsDescription>{translate("settings.costs.hint")}</SettingsDescription>
                    <InternPartDivider/>


                    <Box aria-details={`${translate("settings.project.title")}-${translate("settings.costs.title")}`}
                         ref={node => setRef('projectSettingsCostType', node)}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.costs.title")}</InputLabel>
                            <Select
                                onChange={e => updateProjectSettings({costType: e.target.value})}
                                value={newProjectSettings.costType || CostType.NONE}
                            >
                                {
                                    [CostType.NONE, CostType.GLOBAL, CostType.SPECIFIC, CostType.SEPARATE]
                                        .map(type => {
                                            return <MenuItem key={type}
                                                             value={type}>{CostType.getDescription(type)}</MenuItem>
                                        })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <InternPartDivider/>
                    <CostThresholdSetter value={newProjectSettings.maxDefectCostAlertThreshold}
                                         onChange={(normalizedValue) => updateProjectSettings({maxDefectCostAlertThreshold: normalizedValue})}
                                         setRef={setRef}/>

                </CustomizedInternPaper>


                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={node => setRef('projectSettingsAutomaticAnRequestContainer', node)}>
                    <Stack aria-details={"notSearchable"} flexDirection={"row"} spacing={1} alignItems={"center"}>
                        <InternPartTitle>{translate("settings.configureAutomaticRequests.title")}</InternPartTitle>
                    </Stack>
                    <SettingsDescription>{translate("settings.configureAutomaticRequests.description")}</SettingsDescription>
                    <InternPartDivider/>

                    <Box
                        aria-details={`${translate("settings.project.title")}-${translate("settings.automaticAnRequest.title")}`}
                        ref={(node: any) => setRef('projectSettingsAutomaticAnRequestConfigure', node)}>
                        <NextButton
                            onClick={(e: any) => checkUserRight([USER_AUTHORITIES.ROLE_ADMIN], ()=>openAnotherTab(`/webStatic/projectmanager-autorequests-entry?projectId=${currentProject.id}`))}>{translate("settings.configureAutomaticRequests.title")}</NextButton>
                    </Box>


                </CustomizedInternPaper>

                <EmailCustomizer
                    newProjectSettings={newProjectSettings}
                    updateProjectSettings={updateProjectSettings}
                    setRef={setRef}
                    isResetTriggered={isResetTriggered}
                />

                <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                                       ref={node => setRef('projectSettingsInspectionVisibilityContainer', node)}>
                    <InternPartTitle>{translate("settings.inspectionvisibility.title")}</InternPartTitle>
                    <SettingsDescription>{translate("settings.inspectiontypes.hint")}</SettingsDescription>
                    <InternPartDivider/>
                    <Box
                        aria-details={`${translate("settings.project.title")}-${translate("settings.inspectionvisibility.title")}`}
                        ref={(node: any) => setRef('customerSettingsInspectionTypeVisibility', node)}>
                        <NextButton
                            onClick={(e: any) => openAnotherTab(`/webStatic/projectmanager-inspections-entry?projectId=${currentProject.id}`)}>{translate("settings.toInspectionTypesVisibility.title")}</NextButton>
                    </Box>

                </CustomizedInternPaper>

            </CustomizedPaper>}


            {admin && !projectAdmin && <CustomizedPaper ref={node => setRef('customerSettingsContainer', node)}>
                <PartTitle>{translate("settings.customer.title")}</PartTitle>
                <SettingsDescription>{translate("settings.customer.hint")}</SettingsDescription>
                <PartDivider/>

                <CustomizedInternPaper aria-details={`${translate("settings.customer.title")}`}
                                       ref={node => setRef('userSettingsDocumentContainer', node)}>
                    <InternPartTitle>{translate("settings.documentcreation")}</InternPartTitle>
                    <InternPartDivider/>
                    <Box
                        aria-details={`${translate("settings.customer.title")}-${translate("settings.documentcreation")}`}
                        ref={node => setRef('userSettingsDocument1', node)}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.documentcreation")}</InputLabel>
                            <Select
                                onChange={e => setSelectedDocumentGenerationOptionText(e.target.value)}
                                value={selectedDocumentGenerationOptionText}
                            >
                                {documentGenerationOptions.map((option, index) => {
                                    if (option.divider) {
                                        return <ListSubheader key={index}>{option.text}</ListSubheader>
                                    }
                                    return <MenuItem key={index}
                                                     value={option.text}>{option.text}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <SettingsButtonGroup>
                            {isWebApp &&
                            <Button onClick={downloadPreview}>{translate("settings.downloadpreview")}</Button>}
                            <Button onClick={showPreview}>{translate("settings.showpreview")}</Button>
                            {isWebApp && <Button onClick={downloadAll}>{translate("settings.downloadAll")}</Button>}
                            <Button onClick={changeDocumentTemplate}>{translate("settings.changeText")}</Button>
                        </SettingsButtonGroup>
                        <InternPartDivider/>
                    </Box>

                    <Box
                        aria-details={`${translate("settings.customer.title")}-${translate("settings.documentcreation")}`}
                        ref={node => setRef('userSettingsDocument2', node)}>
                        <SettingsSwitch control={<Switch
                            onChange={e => updateProjectSettings({trackPdfDownload: e.target.checked})}
                            checked={newProjectSettings.trackPdfDownload}/>}
                                        label={translate('settings.trackPdfDownload.title')}/>
                        <SettingsDescriptionWithMarginTop>{translate("settings.trackPdfDownload.hint")}</SettingsDescriptionWithMarginTop>
                        <InternPartDivider/>
                    </Box>

                    <Box
                        aria-details={`${translate("settings.customer.title")}-${translate("settings.documentcreation")}`}
                        ref={node => setRef('userSettingsDocument3', node)}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.printing")}</InputLabel>
                            <Select
                                onChange={e => updateUserSettings({printing: e.target.value})}
                                value={newUserSettings.printing || PrintMode.NORMAL}
                            >
                                {
                                    [PrintMode.NORMAL, PrintMode.DETAIL, PrintMode.FULLHISTORY, PrintMode.COMPACT, PrintMode.COMPACTEXTRA, PrintMode.COMPACTPLANFIRST, PrintMode.PLANEXTRACOLUMN, PrintMode.NOIMAGES, PrintMode.ECO, PrintMode.LONG]
                                        .filter(mode => {
                                            if (mode !== PrintMode.LONG) {
                                                return true;
                                            }
                                            if (newProjectSettings && newProjectSettings.categories && !newProjectSettings.categories.warranty &&
                                                newUserSettings && newUserSettings.printing !== PrintMode.LONG) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .map(mode => {
                                            return <MenuItem key={mode}
                                                             value={mode}>{PrintMode.getPrintModeDescription(mode)}</MenuItem>
                                        })
                                }
                            </Select>
                        </FormControl>
                    </Box>

                    <InternPartDivider/>
                    <DocumentGenerationCommentsConfiguration updateProjectSettings={updateProjectSettings}
                                                             setRef={setRef} newProjectSettings={newProjectSettings}/>

                </CustomizedInternPaper>

                <CustomizedInternPaper aria-details={`${translate("settings.customer.title")}`}
                                       ref={node => setRef('customerSettingsInspectionTypeContainer', node)}>
                    <InternPartTitle>{translate("settings.customertables.title")}</InternPartTitle>
                    <InternPartDivider/>

                    <Box
                        aria-details={`${translate("settings.customer.title")}-${translate("settings.customertables.title")}`}
                        ref={(node: any) => setRef('customerSettingsEscalation', node)}>
                        <NextButton
                            onClick={(e: any) => openAnotherTab(`/webStatic/docs/customers/${currentCustomer}/inspections.json`)}>{translate("settings.inspectiontype.title")}</NextButton>
                        <InternPartDivider/>
                    </Box>

                    <Box
                        aria-details={`${translate("settings.customer.title")}-${translate("settings.customertables.title")}`}
                        ref={(node: any) => setRef('customerSettingsEscalation', node)}>
                        <NextButton
                            onClick={(e: any) => openAnotherTab(`/webStatic/projectmanager-defect-type-config-entry?projectId=${currentProject.id}`)}>{translate("settings.toEscalation.title")}</NextButton>
                    </Box>
                </CustomizedInternPaper>

            </CustomizedPaper>}


            {!restrictedSubcontractorUser && <CustomizedPaper ref={node => setRef('extendedSettingsContainer', node)}>
                    <PartTitle>{translate("settings.extendedSettings.title")}</PartTitle>
                    <PartDivider/>

                    {!showMoreUserSettings && !showMoreSettingsWhenSearch && <FlexContainer aria-details={`${translate("settings.extendedSettings.title")}`}
                                    ref={node => setRef('showUserSettingsExtended', node)}>
                        <AdvancedSettingsButton
                            onClick={() => setShowMoreUserSettings(true)}>{translate("settings.showmoreusersettings")}</AdvancedSettingsButton>
                    </FlexContainer>}

                    {(showMoreUserSettings || showMoreSettingsWhenSearch) && <CustomizedInternPaper aria-details={`${translate("settings.extendedSettings.title")}`}
                                            ref={node => setRef('userSettingsExtendedContainer', node)}>
                        <InternPartTitle>{translate("settings.user.extended.title")}</InternPartTitle>
                        <InternPartDivider/>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedProjectAutoSelect", node)}>
                            <SettingsSwitch control={<Switch checked={newUserSettings.projectAutoSelect}
                                                             onChange={e => updateUserSettings({projectAutoSelect: e.target.checked})}/>}
                                            label={translate('settings.projectautoselect')}/>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedTimeStamp", node)}>
                            <SettingsSwitch control={<Switch checked={newUserSettings.addTimestamp}
                                                             onChange={e => updateUserSettings({addTimestamp: e.target.checked})}/>}
                                            label={translate('settings.addtimestamp')}/>
                            <InternPartDivider/>
                        </Box>


                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedPrincipal", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({addPrincipalId: e.target.checked})}
                                checked={newUserSettings.addPrincipalId}/>}
                                            label={translate('settings.addprincipalid')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.addprincipalid.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}


                        {!subcontractorUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedLocation", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({autoSelectDeepestLocation: e.target.checked})}
                                checked={newUserSettings.autoSelectDeepestLocation}/>}
                                            label={translate('settings.autoSelectDeepestLocation.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.autoSelectDeepestLocation.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}

                        {!principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedLocation", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({hideCompletedActions: e.target.checked})}
                                checked={newUserSettings.hideCompletedActions}/>}
                                            label={translate('settings.hideCompletedActions.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.hideCompletedActions.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}

                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedUpload", node)}>
                            <FormControl variant="filled">
                                <InputLabel>{translate("settings.defaultuploadsettings.title")}</InputLabel>
                                <Select
                                    onChange={e => updateUserSettings({defaultUploadSettings: e.target.value})}
                                    value={newUserSettings.defaultUploadSettings || UploadSetting.AGAN}
                                >
                                    {
                                        [UploadSetting.NONE, UploadSetting.AG, UploadSetting.AN, UploadSetting.AGAN]
                                            .map(setting => {
                                                return <MenuItem key={setting}
                                                                 value={setting}>{UploadSetting.getDescription(setting)}</MenuItem>
                                            })
                                    }
                                </Select>
                            </FormControl>
                            <SettingsDescriptionWithMarginTop>{translate("settings.defaultuploadsettings.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}


                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedSubcontractorOrder", node)}>
                            <FormControl variant="filled">
                                <InputLabel>{translate("settings.subcontractororder")}</InputLabel>
                                <Select
                                    onChange={e => updateUserSettings({subcontractorOrder: e.target.value})}
                                    value={newUserSettings.subcontractorOrder || SubcontractorOrder.TABLE}
                                >
                                    {
                                        [SubcontractorOrder.TABLE, SubcontractorOrder.NAME, SubcontractorOrder.CREW]
                                            .map(order => {
                                                return <MenuItem key={order}
                                                                 value={order}>{SubcontractorOrder.getDescription(order)}</MenuItem>
                                            })
                                    }
                                </Select>
                            </FormControl>
                            <SettingsDescriptionWithMarginTop>{translate("settings.subcontractororder.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedLocationFilter", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({altLocationFilter: e.target.checked})}
                                checked={newUserSettings.altLocationFilter}/>}
                                            label={translate('settings.altlocationfilter')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.altlocationfilter.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>


                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedInstantMessage", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({instantMessageDeadline: e.target.checked})}
                                checked={newUserSettings.instantMessageDeadline}/>}
                                            label={translate('settings.instantmessagedeadlinecheckbox')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.instantmessagedeadline.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>}



                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedQueueTool", node)}>
                            <Button
                                endIcon={<HelpOutlineOutlinedIcon/>}
                                onClick={() => setShowHelpCode(true)}
                                variant={"contained"}>{translate("settings.helpcode")}</Button>
                            <InternPartDivider/>
                        </Box>


                        {!subcontractorUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedTriggerSync", node)}>
                            <Button variant="contained"
                                    endIcon={<SyncOutlinedIcon/>}>{translate("settings.sync.trigger")}</Button>
                            <InternPartDivider/>
                        </Box>}


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedAdditionalInfo", node)}>
                            <FormGroup className="settings-checkbox-container">
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_deadline}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_deadline: e.target.checked}})}
                                    value={additionalInfo.DEFECT_DEADLINE}/>}
                                                  label={translate('settings.defects.additionalinfo.deadline')}/>
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_creator}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_creator: e.target.checked}})}
                                    value={additionalInfo.DEFECT_CREATOR}/>}
                                                  label={translate('settings.defects.additionalinfo.creator')}/>
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_actualisation_date}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_actualisation_date: e.target.checked}})}
                                    value={additionalInfo.ACTUALISATION_DATE}/>}
                                                  label={translate('settings.defects.additionalinfo.lastupdate')}/>
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_creation_date}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_creation_date: e.target.checked}})}
                                    value={additionalInfo.DEFECT_CREATION_DATE}/>}
                                                  label={translate('settings.defects.additionalinfo.created')}/>
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_subcontractor}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_subcontractor: e.target.checked}})}
                                    value={additionalInfo.DEFECT_SUBCONTRACTOR}/>}
                                                  label={translate('settings.defects.additionalinfo.subcontractor')}/>
                                <SettingsCheckbox control={<SettingsInternalCheckbox
                                    checked={newUserSettings.defectMetadata.defect_show_escalationlevel}
                                    onChange={(e: any) => updateUserSettings({defectMetadata: {defect_show_escalationlevel: e.target.checked}})}
                                    value={additionalInfo.DEFECT_ESCALATIONLEVEL}/>}
                                                                 label={translate('settings.defects.additionalinfo.escalationlevel')}/>
                            </FormGroup>
                            <SettingsDescriptionWithMarginTop>{translate("settings.defects.additionalinfo.title")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>


                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedSendAccount", node)}>
                            <SettingsSwitch control={<Switch
                                onChange={e => updateUserSettings({sendAccount: e.target.checked})}
                                checked={newUserSettings.sendAccount}/>}
                                            label={translate('settings.sendaccount')}/>
                            <InternPartDivider/>
                        </Box>}


                        {!subcontractorUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedSignature", node)}>
                            <SignatureCanvas penColor='#111'
                                             ref={signatureRef}
                                             onEnd={drawSignatureEnd}
                                             canvasProps={{
                                                 width: 600,
                                                 height: 300,
                                                 className: 'sigCanvas'
                                             }}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.signature")}</SettingsDescriptionWithMarginTop>
                            {!!newUserSettings.signature && <Button onClick={clearSignature} variant="contained"
                                                                    sx={{marginTop: '.3rem'}}>{translate("clear")}</Button>}
                            <InternPartDivider/>
                        </Box>}


                        {!subcontractorUser && !principalUser && <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.user.extended.title")}`}
                            ref={node => setRef("userSettingsExtendedSchema", node)}>
                            <FormControl variant="filled"
                            >
                                <InputLabel>{translate("settings.filenameschema")}</InputLabel>
                                <Select
                                    onChange={e => updateUserSettings({filenameSchema: e.target.value})}
                                    value={newUserSettings.filenameSchema || FILENAME_SCHEMA.STANDARD}>
                                    {
                                        [FILENAME_SCHEMA.STANDARD, FILENAME_SCHEMA.OTHER, FILENAME_SCHEMA.LONG]
                                            .map(schema => {
                                                return <MenuItem key={schema}
                                                                 value={schema}>{FILENAME_SCHEMA.getDescription(schema)}</MenuItem>
                                            })
                                    }
                                </Select>
                            </FormControl>
                        </Box>}


                    </CustomizedInternPaper>}

                    {showMoreUserSettings && !showMoreSettingsWhenSearch && <FlexContainer aria-details={`${translate("settings.extendedSettings.title")}`}
                                    ref={node => setRef('hideUserSettingsExtended', node)}>
                        <AdvancedSettingsButton
                            onClick={() => setShowMoreUserSettings(false)}>{translate("settings.hidemoreusersettings")}</AdvancedSettingsButton>
                    </FlexContainer>}

                    {!showMoreProjectSettings && !showMoreSettingsWhenSearch && admin && <FlexContainer aria-details={`${translate("settings.extendedSettings.title")}`}
                                    ref={node => setRef('showProjectSettingsExtended', node)}>
                        <AdvancedSettingsButton
                            onClick={() => setShowMoreProjectSettings(true)}>{translate("settings.showmoreprojectsettings")}</AdvancedSettingsButton>
                    </FlexContainer>}

                    {(showMoreProjectSettings || showMoreSettingsWhenSearch) && admin && <CustomizedInternPaper aria-details={`${translate("settings.extendedSettings.title")}`}
                                            ref={node => setRef("projectSettingsExtendedContainer", node)}>

                        <InternPartTitle>{translate("settings.project.extended.title")}</InternPartTitle>
                        <InternPartDivider/>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedExtManual", node)}>
                            <SettingsSwitch control={<Switch
                                checked={newProjectSettings.extManual}
                                onChange={e => updateProjectSettings({extManual: e.target.checked})}/>}
                                            label={translate('settings.extManual.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.extManual.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedExternalDefectsOnly", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.externalDefectsOnly}
                                                             onChange={e => updateProjectSettings({externalDefectsOnly: e.target.checked})}/>}
                                            label={translate('settings.externalDefectsOnly.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.externalDefectsOnly.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedDeactivateReducedDefectlist", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.deactivateReducedDefectlist}
                                                             onChange={e => updateProjectSettings({deactivateReducedDefectlist: e.target.checked})}/>}
                                            label={translate('settings.deactivateReducedDefectlist.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.deactivateReducedDefectlist.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedShowPrincipalInfo", node)}>
                            <SettingsSwitch control={<Switch
                                checked={newProjectSettings.showPrincipalIdsOnPdfs}
                                onChange={e => updateProjectSettings({showPrincipalIdsOnPdfs: e.target.checked})}/>}
                                            label={translate('settings.showPrincipalIdsOnPdfs.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.showPrincipalIdsOnPdfs.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedContactInFileName", node)}>
                            <SettingsSwitch
                                control={<Switch checked={newProjectSettings.showSubcontractorContactInFileName}
                                                 onChange={e => updateProjectSettings({showSubcontractorContactInFileName: e.target.checked})}/>}
                                label={translate('settings.showSubcontractorContactInFileName.title')}/>
                            <InternPartDivider/>
                        </Box>


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedShowYellowAgPins", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.showYellowAgPins}
                                                             onChange={e => updateProjectSettings({showYellowAgPins: e.target.checked})}/>}
                                            label={translate('settings.showYellowAgPins.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.showYellowAgPins.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedChooseSubAutomatically", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.chooseSubAutomatically}
                                                             onChange={e => updateProjectSettings({chooseSubAutomatically: e.target.checked})}/>}
                                            label={translate('settings.chooseSubAutomatically.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.chooseSubAutomatically.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>


                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedAttachVisibleFiles", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.attachVisibleFiles}
                                                             onChange={e => updateProjectSettings({attachVisibleFiles: e.target.checked})}/>}
                                            label={translate('settings.attachVisibleFiles.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.attachVisibleFiles.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedSendNotifications", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.sendNotifications}
                                                             onChange={e => updateProjectSettings({sendNotifications: e.target.checked})}/>}
                                            label={translate('settings.sendNotifications.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.sendNotifications.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedSendCopyWhenDownload", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.sendCopyWhenDownload}
                                                             onChange={e => updateProjectSettings({sendCopyWhenDownload: e.target.checked})}/>}
                                            label={translate('settings.sendCopyWhenDownload.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.sendCopyWhenDownload.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedShowSubcontractorsToPrincipal", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.showSubcontractorsToPrincipal}
                                                             onChange={e => updateProjectSettings({showSubcontractorsToPrincipal: e.target.checked})}/>}
                                            label={translate('settings.showSubcontractorsToPrincipal.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.showSubcontractorsToPrincipal.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsIsDefectSubcontractorOptional", node)}>
                            <SettingsSwitch control={<Switch
                                checked={newProjectSettings.isDefectSubcontractorOptional}
                                onChange={e => updateProjectSettings({isDefectSubcontractorOptional: e.target.checked})}/>}
                                            label={translate('setting.isDefectSubcontractorOptional.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("setting.isDefectSubcontractorOptional.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        {!!currentProject.structureKeys && (currentProject.structureKeys.length > 0) &&
                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef('projectSettingsExtendedMinLocationDepth', node)}>
                            <FormControl variant="filled">
                                <InputLabel>{translate("settings.minLocationDepth.title")}</InputLabel>
                                <Select
                                    onChange={e => updateProjectSettings({minLocationDepth: ((currentProject.structureKeys.findIndex((el: string) => el === e.target.value))+1)})}
                                    value={currentProject.structureKeys[(newProjectSettings.minLocationDepth-1) || 0]}
                                >
                                    {currentProject.structureKeys.map((el: any) => {
                                        return <MenuItem key={el}
                                                         value={el}>{el}</MenuItem>
                                    })}
                                </Select>
                                <SettingsDescriptionWithMarginTop>{translate("settings.minLocationDepth.hint")}</SettingsDescriptionWithMarginTop>
                                <InternPartDivider/>
                            </FormControl>
                        </Box>}

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedAutomaticDataSync", node)}>
                            <TextField
                                variant="filled"
                                label={translate('settings.automaticDataSync.title')}
                                placeholder={(currentProject.hasSync && !savedProjectDataSyncString) ? translate("settings.automaticdatasync.dataloadfailed") : (savedProjectDataSyncString || "")}
                                value={projectDataSyncString}
                                onChange={e => {
                                    setProjectDataSyncString(e.target.value);
                                    try {
                                        updateProjectSettings({sync: JSON.parse(e.target.value)});
                                    } catch (err) {
                                        updateProjectSettings({sync: null});
                                    }
                                }}
                                multiline
                            />
                            {(currentProject.hasSync && !savedProjectDataSyncString) && <Button sx={{marginTop: ".3rem"}} onClick={() => getProjectDataSyncJSON(true)}
                                     variant="contained">{translate("settings.tryloadprojectsyncdataagain")}</Button>}
                            <SettingsDescriptionWithMarginTop>{translate("settings.automaticDataSync.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedDefectSync", node)}>
                            <FormControl variant="filled">
                                <InputLabel>{translate("settings.defectSync.title")}</InputLabel>
                                <Select
                                    onChange={e => {
                                        // @ts-ignore
                                        setDefectSyncDirection(e.target.value)
                                    }}
                                    value={defectSyncDirection}
                                >
                                    {
                                        [DEFECT_SYNC_DIRECTION.TO_PRINCIPAL, DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR]
                                            .map(direction => {
                                                return <MenuItem key={direction}
                                                                 value={direction}>{DEFECT_SYNC_DIRECTION.getDescription(direction)}</MenuItem>
                                            })
                                    }
                                </Select>
                            </FormControl>
                            {defectSyncDirection === DEFECT_SYNC_DIRECTION.TO_PRINCIPAL && <TemplateEditField
                                sx={{marginTop: '.3rem'}}
                                value={defectSyncToPrincipalData}
                                onChange={e => setDefectSyncToPrincipalData(e.target.value)}
                                variant="filled"
                                placeholder={translate("settings.defectsync.entercustomerproject")}
                            />}
                            {defectSyncDirection === DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR &&
                            <Stack spacing={1} sx={{marginTop: '.3rem'}}>
                                {defectSyncToSubcontractorData.map((data) => {
                                    return <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={5} lg={5}>
                                            <FormControl variant="filled">
                                                <InputLabel>{translate("settings.defectsync.selectasubcontractor")}</InputLabel>
                                                <Select
                                                    onChange={e => updateDefectSyncToSubcontractorData(data.subcontractorId, e.target.value)}
                                                    value={data.subcontractorId}
                                                >
                                                    {
                                                        subcontractors.filter((subcontractor: any) => defectSyncToSubcontractorData.map(el => el.subcontractorId).every((ss: string) => ss !== subcontractor.id || ss === data.subcontractorId))
                                                            .map((subcontractor: any) => {
                                                                return <MenuItem key={subcontractor.id}
                                                                                 value={subcontractor.id}>{subcontractor.name}</MenuItem>
                                                            })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={5}>
                                            <DefectSyncTextField
                                                onChange={e => updateDefectSyncToSubcontractorData(data.subcontractorId, data.subcontractorId, e.target.value)}
                                                variant="filled"
                                                placeholder={translate("settings.defectsync.entercustomerproject")}
                                                value={data.defectSyncText}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <Button sx={{padding: '16px 8px'}}
                                                    onClick={() => updateDefectSyncToSubcontractorData(data.subcontractorId)}
                                                    variant={"contained"}>{translate("delete")}</Button>
                                        </Grid>
                                    </Grid>
                                })}
                                {(defectSyncToSubcontractorData.length < subcontractors.length) &&
                                <Button onClick={() => updateDefectSyncToSubcontractorData()}
                                        variant="contained">{translate("settings.defectsync.addanothersynctosubcontractor")}</Button>}
                            </Stack>}
                            <SettingsDescriptionWithMarginTop>{translate("settings.defectSync.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        {!!currentProject.structureKeys && (currentProject.structureKeys.length > 0) &&
                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef('projectSettingsExtendedAnRequestSplitByLocationDepth', node)}>
                            <FormControl variant="filled">
                                <InputLabel>{translate("settings.anRequestSplitByLocationDepth.title")}</InputLabel>
                                <Select
                                    onChange={e => updateProjectSettings({anRequestSplitByLocationDepth: ((currentProject.structureKeys.findIndex((el: string) => el === e.target.value))+1)})}
                                    value={currentProject.structureKeys[(newProjectSettings.anRequestSplitByLocationDepth-1) || 0]}
                                >
                                    {currentProject.structureKeys.map((el: any) => {
                                        return <MenuItem key={el}
                                                         value={el}>{el}</MenuItem>
                                    })}
                                </Select>
                                <SettingsDescriptionWithMarginTop>{translate("settings.anRequestSplitByLocationDepth.hint")}</SettingsDescriptionWithMarginTop>
                                <InternPartDivider/>
                            </FormControl>
                        </Box>}

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedStateDefectTypeSpecial", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.stateDefectTypeSpecial}
                                                             onChange={e => updateProjectSettings({stateDefectTypeSpecial: e.target.checked})}/>}
                                            label={translate('settings.stateDefectTypeSpecial.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.stateDefectTypeSpecial.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedAnCommentInAgList", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.AnCommentInAgList}
                                                             onChange={e => updateProjectSettings({AnCommentInAgList: e.target.checked})}/>}
                                            label={translate('settings.AnCommentInAgList.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.AnCommentInAgList.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedAgCommentInAnList", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.AgCommentInAnList}
                                                             onChange={e => updateProjectSettings({AgCommentInAnList: e.target.checked})}/>}
                                            label={translate('settings.AgCommentInAnList.title')}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.AgCommentInAnList.hint")}</SettingsDescriptionWithMarginTop>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsCreateShareTokenQrCodes", node)}>
                            <SettingsSwitch control={<Switch checked={newProjectSettings.createShareToken}
                                                             onChange={e => updateProjectSettings({createShareToken: e.target.checked})}/>}
                                            label={translate("settings.requestQrCodeShare.title")}/>
                            <SettingsDescriptionWithMarginTop>{translate("settings.requestQrCodeShare.hint")}</SettingsDescriptionWithMarginTop>
                            <DeleteButton onClick={() => openDisableShareTokensDialog()}>{translate("settings.requestQrCodeShare.delete.button")}</DeleteButton>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedSubcontractorAccess", node)}>
                            <NextButton
                                onClick={() => openAnotherTab(`/webStatic/data/table/customers/${currentCustomer}/projects/${currentProject.id}/subcontractors_access.csv`)}
                                variant={"contained"}>{translate("settings.subcontractoraccess.title")}</NextButton>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedAutocompletion", node)}>
                            <NextButton
                                onClick={() => openAnotherTab(`/webStatic/data/table/customers/${currentCustomer}/projects/${currentProject.id}/completions.csv`)}
                                variant={"contained"}>{translate("settings.autocompletion.title")}</NextButton>
                            <InternPartDivider/>
                        </Box>

                        <Box
                            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                            ref={node => setRef("projectSettingsExtendedDistributorList", node)}>
                            <NextButton
                                onClick={() => openAnotherTab(`/webStatic/data/table/customers/${currentCustomer}/projects/${currentProject.id}/mailing_list.csv`)}
                                variant={"contained"}>{translate("settings.distributorlist.title")}</NextButton>
                            <InternPartDivider/>
                        </Box>

                    </CustomizedInternPaper>}

                    {showMoreProjectSettings && !showMoreSettingsWhenSearch && admin && <FlexContainer aria-details={`${translate("settings.extendedSettings.title")}`}
                                    ref={node => setRef('hideProjectSettingsExtended', node)}>
                        <AdvancedSettingsButton
                            onClick={() => setShowMoreProjectSettings(false)}>{translate("settings.hidemoreprojectsettings")}</AdvancedSettingsButton>
                    </FlexContainer>}
                </CustomizedPaper>}

                {noSettingsMatch && <CustomizedPaper>
                    <Stack aria-details={"notSearchable"} flexDirection={"row"} spacing={1} alignItems={"center"}>
                        <InternPartTitle sx={{fontStyle: 'italic'}}>{translate("settings.nomatchitem")}</InternPartTitle>
                        <FmdBadOutlinedIcon style={{marginTop: 0}}/>
                    </Stack>
                </CustomizedPaper>}
        </SettingsContainer>
        <CustomizedDialog open={!!template && !saveTemplateValidationCode} onClose={closeTemplateEditDialog}>
            <DialogTitle>{getDocumentGenerationOption().text}</DialogTitle>
            <DialogContent>
                <Stack flexDirection={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                    {!!template && Object.keys(template.fileMap).sort().map((el:string, index) => {
                        return <TemplateEditField
                            onChange={e => updateTemplate({fileMap:{[el]:e.target.value}})}
                            disabled={!template.isEditable || !template.isEditable[el]}
                            key={index}
                            variant="filled"
                            value={template.fileMap[el]}
                            multiline
                        />
                    })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeTemplateEditDialog}>{translate("cancel")}</Button>
                <Button disabled={!templateChanged} onClick={saveTemplateClicked}>{translate("requestbundle.savePermanently")}</Button>
            </DialogActions>
        </CustomizedDialog>
        <CustomizedDialog open={!!saveTemplateValidationCode} onClose={closeSaveTemplateValidationDialog}>
            <DialogTitle>{`${translate("requestbundle.safetycode")} ${saveTemplateValidationCode}`}</DialogTitle>
            <DialogContent>
                <TemplateEditField
                    variant="filled"
                    value={userTippedSaveTemplateValidationCode}
                    onChange={e => setUserTippedSaveTemplateValidationCode(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeSaveTemplateValidationDialog}>{translate("cancel")}</Button>
                <Button onClick={e => saveNewTemplate(false)} disabled={saveTemplateValidationCode !== userTippedSaveTemplateValidationCode}>{translate("requestbundle.customer")}</Button>
                <Button onClick={e => saveNewTemplate(true)} disabled={saveTemplateValidationCode !== userTippedSaveTemplateValidationCode}>{translate("requestbundle.project")}</Button>
            </DialogActions>
        </CustomizedDialog>

        <CustomizedDialog open={showHelpCode} onClose={closeHelpCodeDialog}>
            <DialogTitle>{translate("settings.enterhelpcode")}</DialogTitle>
            <DialogContent>
                <TemplateEditField
                    variant="filled"
                    value={userTippedHelpCode}
                    onChange={e => setUserTippedHelpCode(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeHelpCodeDialog}>{translate("cancel")}</Button>
                <Button onClick={validateUserTippedHelpCode} disabled={!userTippedHelpCode}>{translate("ok")}</Button>
            </DialogActions>
        </CustomizedDialog>
    </PageContainer>
}

export default Settings;