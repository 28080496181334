define([
	'./lib/jquery-1.11.0', './lib/lodash-2.4.1.compat', './view/Popup', './helper/watchdog', './model/user', './helper/iosapp', './helper/commonusercomm', './helper/idutil', './helper/appStateComm',
	'./config', './Router', './model/ma_accounts', './model/proxyInfo', './lib/jquery.mobile-1.4.2.mod'
], function (
	$, _, Popup, watchdog, user, iosapp, commonusercomm, idutil, appStateComm,
	config, Router,  ma_accounts, proxyInfo
) {

	function sendOfflineErrors () {
		if (watchdog.isConnected()) {
			var offlineErrors = window.localStorage.getItem('offlineErrors');
			if (!offlineErrors) {
				return;
			}
			try {
				offlineErrors = JSON.parse(offlineErrors);
			} catch (e) {
				if (!offlineErrors) {
					offlineErrors = [];
				}
			}
			$.ajax({
				type: 'POST',
				url:  '/api/offline-errors',
				data: JSON.stringify(offlineErrors),
				contentType: 'application/json',
				global: false
			}).always(function () {
				window.localStorage.removeItem('offlineErrors');
			}.bind(this));
		}
	}

	setTimeout(sendOfflineErrors, 10000);
	watchdog.on('change:connected', sendOfflineErrors);

	if (!window.dev) {
		window.onerror = function (message, source, row, column, error) {
			var data = {
				message: message + '\n' + (error || new Error()).stack,
				source: source
			};

			//TODO: remove when nobody uses app 1.2 any more.
			if ((data.message.indexOf('SyntaxError') !== -1 || data.message.indexOf('JSON Parse error') !== -1) && !!window.oxcatchfaileddeserialize) {
				window.alertPopup('Achtung, eine Änderung konnte nicht gespeichert werden da spezielle Zeichen wie Anführungszeichen oder Zeilenumbrüche eingegeben wurden (siehe Mangelbeschreibung, Kommentare etc.) Bitte führen Sie im App-Store das Update auf die neueste Version aus.');
				if (window.oxcatchfaileddeserialize) {
					window.oxcatchfaileddeserialize();
				}
			}

			if (_.isFinite(row)) {
				data.row = row;
			}
			if (_.isFinite(column)) {
				data.column = column;
			}

			if (watchdog.isConnected()) {
				iosapp.getsysteminfo(function (systeminfo) {
					data.systeminfo = systeminfo;
					$.ajax({
						type: 'POST',
						url:  '/api/error',
						data: data,
						global: false
					});
				});
			} else {
				if ((typeof Storage !== 'undefined' && navigator.cookieEnabled)) {
					var offlineErrors = window.localStorage.getItem('offlineErrors');
					if (!offlineErrors) {
						offlineErrors = '[]';
					}
					try {
						offlineErrors = JSON.parse(offlineErrors);
					} catch (e) {
						if (!offlineErrors) {
							offlineErrors = [];
						}
					}
					offlineErrors.push(data);
					window.localStorage.setItem('offlineErrors', JSON.stringify(offlineErrors));
				}
			}
			return true;
		};
	}

	var $hilight = $('.screenshot-hilight');
	var $hilightCursor = $hilight.find('.cursor');
	window.hilightOn = function (selector) {
		var rect = $(selector)[0].getBoundingClientRect();
		$hilight.css({
			top:    rect.top   .toFixed(0) + 'px',
			left:   rect.left  .toFixed(0) + 'px',
			width:  rect.width .toFixed(0) + 'px',
			height: rect.height.toFixed(0) + 'px'
		}).show();
	};
	window.hilightCursor = function (position) {
		$hilightCursor.css(position).show();
	};
	window.hilightOff = function () {
		$hilight.hide();
		$hilightCursor.hide();
	};

	window.popup = function (options) { return new Popup(options).render(); };
	window.confirmPopup = function (message) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: user.translate('popup.title'), message: message, buttons: [ 'cancel', 'ok' ] }).render();
		popup.on('confirm', function () { popup.closedDeferred.done(function () {deferred.resolve();}) } );
		popup.on('cancel', function () { popup.closedDeferred.done(function () {deferred.reject();}) } );
		return deferred.promise();
	};
	window.yesNoPopup = function (message) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: user.translate('popup.title'), message: message, buttons: [
			{
				text:  user.translate("popup.no"),
				event: 'no'
			},
			{
				text:  user.translate("popup.yes"),
				event: 'yes'
			}]
		});
		popup.$el.css({
			'max-width': '600px'
		});
		popup.render();
		popup.on('yes', function () { popup.closedDeferred.done(function () {deferred.resolve();}) } );
		popup.on('no', function () { popup.closedDeferred.done(function () {deferred.reject();}) } );
		return deferred.promise();
	};
	window.linkPopup = function(copyable, open) {
		var deferred = $.Deferred();
		var buttons = ['copylink', 'ok'];
		if (open) {
			buttons.unshift({ text: user.translate('popup.openlink'), event: 'open' });
		}

		var popup = new Popup({ header: user.translate('popup.title.link'), copyable: copyable, buttons: buttons });
		popup.$el.addClass('popup-link');
		popup.on('open', function () {
			window.open(copyable);
			popup.closedDeferred.done(function () {deferred.resolve();});
		});
		popup.on('ok', function () { popup.closedDeferred.done(function () {deferred.resolve();}) } );
		popup.on('copylink', function () {
			if (window.clipboardData && window.clipboardData.setData) {
				// IE specific code path to prevent textarea being shown while dialog is visible.
				return clipboardData.setData("Text", copyable);
			} else {
				navigator.clipboard.writeText(copyable).then(
					function () {
						popup.closedDeferred.done(function () {deferred.resolve();});
					},
					function (ex) {
						console.warn("Copy to clipboard failed.", ex);
						popup.closedDeferred.done(function () {deferred.reject();});
					}
				);
			}
		});
		popup.$('input').on('focus', function (e) {
			$(e.target).select();
		});
		popup.render();
		return deferred.promise();
	};
	window.commentPopup = function(type, readonly){
		var deferred = $.Deferred();
		var popup = new Popup({ header: user.translate('popup.title.comment.' + type.toLowerCase()), comment: '', buttons: (readonly ? ['ok'] : ['cancel', 'ok']) }).render();
		popup.$('textarea').css('max-width', ($(window).width() - 50));
		popup.$('textarea').toggleClass('ui-disabled', !!readonly);
		popup.on('confirm', function () { popup.closedDeferred.done(function () {if (!readonly) deferred.resolve(); else deferred.reject(); } ) });
		popup.on('cancel', function () { popup.closedDeferred.done(function () {deferred.reject();}) } );
		return {deferred: deferred.promise(), comment: popup.$el.find('textarea'), type: type, popup: popup};
	};
	window.yesNoCancelPopup = function (message, altNoTranslationKey, altYesTranslationKey) {
		altNoTranslationKey = altNoTranslationKey || 'popup.no';
		altYesTranslationKey = altYesTranslationKey || 'popup.yes';
		var deferred = $.Deferred();
		var popup = new Popup({ header: user.translate('popup.title'), message: message, buttons: ['cancel', {
				text:  user.translate(altNoTranslationKey),
				event: 'no'
			}, {
				text:  user.translate(altYesTranslationKey),
				event: 'yes'
			}] }).render();
		popup.on('yes', function () { popup.closedDeferred.done(function () {deferred.resolve('yes');}) } );
		popup.on('no', function () { popup.closedDeferred.done(function () {deferred.reject('no');}) } );
		popup.on('cancel', function () { popup.closedDeferred.done(function () {deferred.reject('cancel');}) } );
		return deferred.promise();
	};
	window.cancelPopup = function (message) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: user.translate('popup.title'), message: message, buttons: ['cancel'] }).render();
		popup.on('cancel', function () { popup.closedDeferred.done(function () {deferred.reject('cancel');}) } );
		return deferred.promise();
	};
	window.alertPopup = function (message, title) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: title || user.translate('popup.title'), message: message, buttons: [ 'ok' ] }).render();
		popup.on('confirm', function () { popup.closedDeferred.done(function () {deferred.resolve();}) } );
		return deferred.promise();
	};
	window.hotlinePopup = function (number, address, message, title) {
		var deferred = $.Deferred();
		var buttons = [];
		if(iosapp.appavailable) {
			buttons.push({text: user.translate('popup.callhotline'), event: 'callhotline', close: true});
			buttons.push({text: user.translate('popup.writeemail'), event: 'writeemail', close: true});
		}
		buttons.push({text: 'Hotlinecode', event: 'hotlinecode', close: false});
		buttons.push('ok' );
		var popup = new Popup({ header: title || user.translate('popup.title'), message: message, buttons: buttons }).render();
		popup.on('confirm', function () { popup.closedDeferred.done(function () {deferred.resolve();}) } );
		popup.on('callhotline', function () { iosapp.call('callHotline', {number: number}, undefined, undefined);} );
		popup.on('writeemail', function () { iosapp.call('writeEmail', {address: address}, undefined, undefined);} );
		popup.on('hotlinecode', function () {
			var textpopup = "<div class='hotlinetext ui-content ui-mini'>\n\
								<fieldset data-role=\"controlgroup\" data-type=\"horizontal\" class=\"ui-controlgroup ui-controlgroup-horizontal ui-corner-all\">\n\
									<span style='width: 100%; text-align: center; display: block'>Bitte Code vom Support hier eingeben:</span>\n\
									<div class=\"ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset\">\n\
										<input type='text' placeholder='Code'>\n\
									</div>\n\
									<button class='ui-btn ui-btn-a ui-btn-inline ui-shadow ui-corner-all' style='width: 100%;text-align: center;margin-top: 0;'>Bestätigen</button>\n\
								</fieldset>\n\
							</div>";
			if(popup.$('.hotlinetext').length === 0) popup.$('div.ui-content').append(textpopup);
			textpopup = popup.$('.hotlinetext');
			textpopup.css({'width': '100%','height': '40px'}).find('button').on('click', function () {
				//Code to execute when clicking the button goes here!
				var input = textpopup.find('input');
				var code = (input.val() || '').toUpperCase();
				if(code === 'testcode') {
					input.css({
						'color': 'unset',
						'border': 'unset'
					});
					//Code to execute when the users code is correct
				} else if (code === 'QT') {
					$('body').data('cancelbeforeunload', true);
					window.location.href =  '/service#queuetool';
				} else {
					input.css({
						'color': 'red',
						'border': '1px solid red'
					})
					//Additional code to execute when the users code is incorrect
				}
			}.bind(this))
		}.bind(this));
		return deferred.promise();
	};

	window.noSaveTopicsPopup = function (message, title, topicsList) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: title || user.translate('popup.title'), message: message, buttons: ['cancel', 'ok'] }).render();
		popup.$('div.ui-content').append($('<select class="defecttopicschooser">'));
		_.each(topicsList, function (e) {
			popup.$('.defecttopicschooser').append($('<option value="' + e.id + '">').text(e.value));
		})
		popup.$('.defecttopicschooser').selectmenu();
		popup.$('.ui-select').css({
			paddingLeft: '10px',
			paddingRight: '10px'
		});
		popup.$('.buttons').parent().append(popup.$('.buttons'));
		popup.$('.buttons').css({
			float: 'none',
			textAlign: 'right'
		});
		popup.on('confirm', function () { popup.closedDeferred.done(function () {deferred.resolve(popup.$('.defecttopicschooser').val());}) } );
		return deferred.promise();
	};

	window.syncCostPopup = function (costAG, costAN) {
		var deferred = $.Deferred();

		var hasChoice = (costAG !== null && costAN !== null && costAG !== costAN);
		var buttons = [];
		if (!hasChoice) buttons.push('ok');
		buttons.push('cancel');

		var content = $('<div></div>');
		var descrText = $('<p>' + user.translate('defect.synccost.popup.description') + '</p>');
		var choiceText = $('<p>' + user.translate('defect.synccost.popup.choice') + '</p>');
		var choiceButtonRow = $('<div></div>');
		var chooseAgCostButton = $('<div class="chooseCostButton ui-btn ui-corner-all ui-shadow">' + user.translate('principal.title') + '<br><br>' + user.translate('defect.cost') + ' ' + costAG + '€</div>');
		var chooseAnCostButton = $('<div class="chooseCostButton ui-btn ui-corner-all ui-shadow">' + user.translate('subcontractor.title') + '<br><br>' + user.translate('defect.cost') + ' ' + costAN + '€</div>');

		content.append(descrText);

		if (hasChoice) {
			choiceButtonRow.append(chooseAgCostButton, chooseAnCostButton);
			content.append(choiceText, choiceButtonRow);
		}

		content.css({
			display: 'block',
			'max-width': '600px',
			'white-space': 'pre-line'
		});
		choiceButtonRow.css({
			display: 'flex',
			'justify-content': 'space-evenly',
			gap: '15px',
			padding: '5px 15px 5px 15px'
		});

		var popup = new Popup({
			header: user.translate('defect.synccost.popup'),
			content: content,
			buttons: buttons}).render();

		chooseAnCostButton.on('click', function () {
			popup.closedDeferred.done(function () { deferred.resolve(costAN); });
			popup.close();
		});
		chooseAgCostButton.on('click', function () {
			popup.closedDeferred.done(function () { deferred.resolve(costAG); });
			popup.close();
		});
		popup.on('confirm', function () {
			popup.closedDeferred.done(function () { deferred.resolve(costAN ? costAN : costAG); });
		});
		popup.on('cancel', function () {
			popup.closedDeferred.done(function () { deferred.reject(); });
		});

		return deferred.promise();
	};

	window.alertPopupSubcontractorChangeRejected = function (message, title, filterMode, filterCallback, resetCustomDefectIDFilterCallback) {
		var deferred = $.Deferred();
		var popup = new Popup({ header: title || user.translate('popup.title'), message: message, buttons: [ {
				text: user.translate('popup.default.showdefects'),
				event: 'showDefects'
			}, 'ok'] }).render();

		popup.on('confirm', function () {
			resetCustomDefectIDFilterCallback();
			popup.closedDeferred.done(function () {deferred.resolve();}) } );

		popup.on('showDefects', function () {
			filterCallback();
			popup.closedDeferred.done(function () {deferred.resolve();}) }
		);

		return deferred.promise();
	};

	window.registrationRequestPopup = function() {
		var deferred = $.Deferred();
		this.yesNoPopup(user.translate('defect.requestQrCodeShare.registration.popup'))
			.done(function () {
				$.mobile.loading('show');
				$.post('/onlineBauabnahme/api/get-subcontractor-credentials', {}).then(
					function (link) {
						$.mobile.loading('hide');
						if (link) {
							this.linkPopup(link, true).then(
								function() {deferred.resolve();}
							);
						} else {
							deferred.reject();
						}
					}.bind(this),
					function () {
						$.mobile.loading('hide');
						this.alertPopup(user.translate('paperwork.showdocument.error')).then(
							function() {deferred.reject();}
						);
					}.bind(this)
				);
			}.bind(this))
			.fail(function () {deferred.reject();});
		return deferred.promise();
	};

	window.escapeHTML = function (unsafe) {
		return unsafe
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	};

	$(document).on('pageshow', function() {
		iosapp.onloadevent('pageshow');
	});



	var ua = navigator.userAgent.toLowerCase();
	window.oxmobile = (ua.indexOf('mobile') !== -1 || ua.indexOf('android') !== -1);

	var inIframe;
	try {
		inIframe = (window.self !== window.top);
	} catch (e) {
		inIframe = true;
	}

	//check for iframe: in firefox cookieEnabled is set to false if in iframe with third party cookies disabled (PMG)
	if (!inIframe && !navigator.cookieEnabled) {
		$('<div style="margin: 1em">Um den Service Bau-Mängel nutzen zu können, aktivieren Sie bitte Cookies in den Internet-Einstellungen und laden Sie die Seite erneut.</div>').appendTo('body');
		return;
	}

	var deferred = $.Deferred();
	appStateComm.setTranslationsFailed(false);
	proxyInfo.fetch().always(function() {
		let userXhr = user.fetch();
		userXhr.fail(function() {
			appStateComm.setTranslationsFailed(true);
		});
		userXhr.always(function () {
			ma_accounts.fetch().always(function () {
				$(document).one('pageshow', function () {
					if ((typeof Storage !== 'undefined' && navigator.cookieEnabled)) {
						if (localStorage.getItem('preventFullscreenWarning')) {
							localStorage.removeItem('preventFullscreenWarning');
							return;
						}
					}
					if (!!window.navigator.standalone) {
						window.alertPopup(user.translate('popup.homescreenwarning'));
					}
				});

				if (user.has('tiles')) {
					//preload tiles
					_.each(user.get('tiles'), function (file) {
						(new Image()).src = window.contextPath + '/tiles/' + file;
					});
				}

				var router = new Router();
				commonusercomm.router = router;
				watchdog.check();
				deferred.resolve();
			});
		});
	});
	return deferred;
});
