import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {useLocation, useParams} from "react-router-dom";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import {awaitWrap, isUserPrincipal, isUserReadonly, isUserSubcontractor, translate} from "../utils/utils";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import IconButton from "@mui/material/IconButton";
import {Typography} from "@mui/material";
import {serviceDataCommunicationCommand} from "../utils/constants";
import {getBackboneModel} from "../utils/backboneCommunicationUtils";
import {getSubcontractorRequestInfo} from "../service/apis";

const Defects = () => {

    const {state} = useLocation();
    const {uuid, shareToken} = useParams();

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);

    useEffect(()=>{
        if(!!uuid) {
            return;
        }

        (async () => {
            if (shareToken) {
                let [e, r] = await awaitWrap(getSubcontractorRequestInfo(shareToken));

                if (e == null && r) {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    model.resetFilter();
                    model.elementFilter.subcontractor = r.data.subcontractor;
                    model.elementFilter.requestDefects = r.data.defects || [];
                }
            }
            // @ts-ignore
            (!!state && state.principal) ? window.principalUseEffect() : window.defectsUseEffect((!!state&&!!state.inspectionId) ? state.inspectionId : null, (!!state && state.command === serviceDataCommunicationCommand.LINK_DEFECTS) ? state : null);
        })();
    },[])

    useEffect(()=>{
        if(!uuid) {
            return;
        }
        // @ts-ignore
        (!!state && state.principal) ? window.principalUseEffect() : window.defectsUseEffect((!!state&&!!state.inspectionId) ? state.inspectionId : null, (!!state && state.command === serviceDataCommunicationCommand.LINK_DEFECTS) ? state : null);
    },[uuid])

    let right = [
        <StyledButton key="newDefect" className="newDefect" >{translate("defects.newdefect")}</StyledButton>,
        <StyledIconButton key="actionsTrigger" className="options-menu-button actionsTrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]
    if(isUserReadonly(user)) {
        right.splice(0,1);
    }

    const middle = [<IconButton key="defects-only-favourite-btn" className="defects-only-favourite">
        {<StarBorderRoundedIcon className="filter-only-favourite-no"/>}
        {<StarRateRoundedIcon className="filter-only-favourite-yes"/>}
    </IconButton>, <Typography key="page-title" variant={"h4"} sx={{color:'black', display: {xs: 'none', sm: 'block'}}}>{translate("defects.title")}</Typography>]

    if(isUserSubcontractor(user) || isUserPrincipal(user)) {
        middle.splice(0,1);
    }

    return <PageContainer newPage={false}
                          left={<StyledButton className="previousPage">{translate("defects.back")}</StyledButton>}
                          middle={middle}
                          right={right}
    />
}

export default Defects;