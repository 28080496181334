import React, {useEffect, useState, useContext} from "react";
import {Navigate, Route, Routes, useNavigate, useParams} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import {Spinner} from "./components/spinner";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {CommonStoreStateKeys, CommonStoreStatePayload, setStateValue} from "./store/slices/commonStoreSlice";
import {
    awaitWrap,
    getCustomerIdFromCustomerProject,
    getProjectIdFromCustomerProject,
    isUserPrincipal,
    isUserSubcontractor,
    setHtmlLanguageTag,
    setTranslations,
    updateCookie
} from "./utils/utils";
import {
    getAccessibleAccounts,
    getAttachmentSources,
    getCompletions,
    getCurrentDefects,
    getCurrentInspection,
    getCurrentProject,
    getCurrentStats,
    getHelp,
    getInspections,
    getParticipants,
    getPolygonsAndCfgs,
    getProjectFiles,
    getProjectFileSources,
    getProjects,
    getRealWorldPlanTransformations,
    getRecordPoints,
    getSubcontractors,
    getUser,
    postCustomer
} from "./service/apis";
import {
    disableBeforeUnloadEvent,
    dispatchDataWithCallback,
    getBackboneModel,
    jqueryDeferredWrap,
    getTranslationsFailed,
    setTranslationsFailed
} from "./utils/backboneCommunicationUtils";
// @ts-ignore
import _ from "lodash";
import {
    APP_FUNCTION_NAME,
    callFunctionInApp,
    isAndroidApp,
    isIOSApp,
    isWebApp,
    operateDataFromAnApplication,
} from "./utils/appCommunicationUtils";
import Account from "./pages/Account";
import ProjectSelection from "./pages/ProjectSelection";
import Project from "./pages/Project";
import {DialogInfo} from "./components/PageContainer";
import {localStorageItem} from "./utils/constants";
import {SimpleChoiceDialog} from "@bau/material";
import {versionCheck} from "./service/watchdog";
import ForceCustomerProject from "./pages/ForceCustomerProject";
import Login from "./pages/Login";
import Inspections from "./pages/Inspections";
import NewInspection from "./pages/NewInspection";
import Inspection from "./pages/Inspection";
import Defect from "./pages/Defect";
import Defects from "./pages/Defects";
import Location from "./pages/Location";
import Files from "./pages/Files";
import Image from "./pages/Image";
import Documents from "./pages/Documents";
import RequestBundle from "./pages/RequestBundle";
import Subcontractors from "./pages/Subcontractors";
import Subcontractor from "./pages/Subcontractor";
import SubcontractorBundle from "./pages/SubcontractorBundle";
import Cost from "./pages/Cost";
import SelectInspection from "./pages/SelectInspection";
import PrincipalBundle from "./pages/PrincipalBundle";
import InstantMessage from "./pages/InstantMessage";
import Paperwork from "./pages/Paperwork";
import Statistics from "./pages/Statistics";
import Filter from "./pages/Filter";
import News from "./pages/News";
import Help from "./pages/Help";
import store from "./store/store";
import PersonCSV from "./pages/PersonCSV";
import BuyerAccess from "./pages/BuyerAccess";
import BuyerRoles from "./pages/BuyerRoles";
import WarrantyDataOverview from "./pages/WarrantyDataOverview";
import QueueTool from "./pages/QueueTool";
import {handleLocalstorageItemFromOtherServices} from "./utils/servicesCommunicationUtils";
import Settings from "./pages/settings/Settings";
import Hotline from "./pages/Hotline";
import {AppStateContext, AppStateProvider} from "./components/AppStateProvider";

const lazyLoad = (pageName:string) => {
    let Com = React.lazy(()=> import (`./pages/${pageName}`));
    return (
        <React.Suspense fallback={<Spinner/>}>
            <Com/>
        </React.Suspense>
    )
}

interface DataSetterProps {
    children?: React.ReactNode
}

// @ts-ignore
const UserSetter: React.FC<DataSetterProps> = (props) => {

    const [versionCheckInterval, setVersionCheckInterval] = useState<NodeJS.Timeout | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {customerProject} = useParams();
    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);

    useEffect(()=> ()=>{clearTimeout(versionCheckInterval)}, [])

    const fetchUser = async (newCustomerId?:string)=>{
        setIsLoading(true);
        clearTimeout(versionCheckInterval);
        if(!!newCustomerId) {
            await awaitWrap(postCustomer(newCustomerId));
        }
        let [e1, userRes] = await awaitWrap(getUser());
        let [e2, helpRes] = await awaitWrap(getHelp());
        let [e3, customers] = await awaitWrap(getAccessibleAccounts());
        if(!!e1 || !!e3) {
            if(!!e1 && !!e1.response && e1.response.status === 403) {
                navigate("/login");
                return;
            }
            navigate("/error");
            return;
        }

        let userCustomer = userRes?.data.customer;
        if (customerProject && userCustomer !== getCustomerIdFromCustomerProject(customerProject)) {
            navigate("/error");
            return;
        }

        if (userRes?.headers['x-oa-customer'] && userRes?.headers['x-oa-subcontractor']) {
            await postCustomer(userRes?.headers['x-oa-customer'] + '-' + userRes?.headers['x-oa-subcontractor']);
        }

        updateCookie();
        let translations = !!userRes ? userRes.data.translations : {};
        setTranslations(translations);
        setHtmlLanguageTag(userRes);
        dispatchDataWithCallback( true, CommonStoreStateKeys.user, userRes?.data,
            dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.user, value: dataFromBackbone})));
        dispatch(setStateValue({
            key: CommonStoreStateKeys.currentCustomer,
            value: userCustomer
        }))
        if(!!userCustomer) {
            localStorage.setItem(localStorageItem.OA_CUSTOMER, userCustomer);
        }

        dispatchDataWithCallback(true, CommonStoreStateKeys.projects, [],
            dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.projects, value: dataFromBackbone})));
        let projectItems = [
            {
                key: CommonStoreStateKeys.currentProject,
                value: {}
            },
            {
                key: CommonStoreStateKeys.inspections,
                value: []
            },
            {
                key: CommonStoreStateKeys.subcontractors,
                value: []
            },
            {
                key: CommonStoreStateKeys.polygonsAndCfgs,
                value: {}
            },
            {
                key: CommonStoreStateKeys.realWorldPlanTransformations,
                value: {}
            },
            {
                key: CommonStoreStateKeys.recordPoints,
                value: {}
            },
            {
                key: CommonStoreStateKeys.currentDefects,
                value: []
            },
            {
                key: CommonStoreStateKeys.completions,
                value: {}
            },
            {
                key: CommonStoreStateKeys.projectFiles,
                value: {}
            },
            {
                key: CommonStoreStateKeys.currentStats,
                value: {}
            },
            {
                key: CommonStoreStateKeys.participants,
                value: []
            },
            {
                key: CommonStoreStateKeys.attachmentSources,
                value: []
            },
            {
                key: CommonStoreStateKeys.projectFileSources,
                value: []
            },
        ]
        setProject(projectItems);
        let initStats = {externalOnly: isUserPrincipal(userRes?.data), subcontractorId: isUserSubcontractor(userRes?.data)};
        dispatchDataWithCallback( true, CommonStoreStateKeys.currentStats, initStats,
            dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.currentStats, value: dataFromBackbone})));
        if(!e2) {
            dispatchDataWithCallback(true, CommonStoreStateKeys.help, helpRes?.data,
                dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.help, value: dataFromBackbone})));
        }
        dispatchDataWithCallback(true, CommonStoreStateKeys.accessibleAccounts, customers?.data,
            dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.accessibleAccounts, value: dataFromBackbone})));
        setIsLoading(false);
        let interval = setInterval(()=>{
            versionCheck();
        }, 20 * 1000);

        let newWatchdog = {canUpdateLocalDefectDate: true};
        dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, newWatchdog, dataFromBackbone => {
            dispatch(setStateValue({
                key: CommonStoreStateKeys.watchdog,
                value: dataFromBackbone
            }))
        })
        versionCheck();
        setVersionCheckInterval(interval);
    }

    useEffect(()=>{
        const pathCustomer = getCustomerIdFromCustomerProject(customerProject);
        if(!pathCustomer) {
            if(!currentCustomer) {
                fetchUser();
            }
            return;
        }
        dispatch(setStateValue({
            key: CommonStoreStateKeys.currentCustomer,
            value: pathCustomer
        }));
    }, [customerProject])

    useEffect(()=>{
        if(!!currentCustomer && user.customer !== currentCustomer) {
            fetchUser(currentCustomer);
        }
    }, [currentCustomer])

    return isLoading ?
        <Spinner/>
        :
        props.children

}

// @ts-ignore
const ProjectsSetter: React.FC<DataSetterProps> = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let projects = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.projects]);
    let currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);

    useEffect(()=>{
        (async () => {
            if(!currentCustomer) {
                return;
            }
            if(!!projects && projects.length > 0) {
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            let [e1, projectsRes] = await awaitWrap(getProjects());
            if(!!e1) {
                navigate("/error", {state:{error:'fetch projects failed'}});
                return;
            }
            dispatchDataWithCallback(true, CommonStoreStateKeys.projects, projectsRes?.data,
                (dataFromBackbone => dispatch(setStateValue({key: CommonStoreStateKeys.projects, value: dataFromBackbone}))));
            setIsLoading(false);
        })()
    }, [])

    return isLoading ?
        <Spinner/>
        :
        props.children

}


const setProject = (projectItems: CommonStoreStatePayload[]) => {
    let dispatch = store.dispatch;
    projectItems.forEach((item) => {
        dispatchDataWithCallback(true, item.key, item.value, dataFromBackbone => {
            dispatch(setStateValue({
                key: item.key,
                value: dataFromBackbone
            }));
        })
    })
    let currentInspection = {
        participants: [],
        emailReceivers: [],
        finished: false,
        archive: false,
        useExternalIDs: false,
        deadline: null,
        freetext: '',
        groupBy: 'none',
        customerProperties: {}
    };
    dispatchDataWithCallback(true, CommonStoreStateKeys.currentInspection, currentInspection, dataFromBackbone => {
        dispatch(setStateValue({
            key: CommonStoreStateKeys.currentInspection,
            value: dataFromBackbone
        }))
    })
}


// @ts-ignore
const ProjectSetter: React.FC<DataSetterProps> = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const { appState, setAppState } = useContext(AppStateContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {customerProject} = useParams();


    let user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    let currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    let currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);

    async function fetchProject(projectId: string) {
        let currentProject = (await getCurrentProject(projectId)).data;
        let inspections = (await getInspections(projectId)).data;
        let subcontractors = (await getSubcontractors(projectId)).data;
        let polygonsAndCfgs = (await getPolygonsAndCfgs(projectId)).data;
        let realWorldPlanTransformations = (await getRealWorldPlanTransformations(projectId)).data;
        let recordPoints = (await getRecordPoints(projectId)).data;
        const useReducedDefects = !!currentProject.useReducedDefectlist;
        setAppState((prev) => ({...prev, usingReducedDefectList: !!useReducedDefects}));
        let currentDefects = (await getCurrentDefects(projectId, useReducedDefects)).data;
        let completions = (await getCompletions(projectId)).data;
        let projectFiles = (await getProjectFiles(projectId)).data;
        let currentStats = (await getCurrentStats(projectId, isUserSubcontractor(user))).data;
        let participants = (await getParticipants(projectId)).data;
        let attachmentSources = (await getAttachmentSources(projectId)).data;
        let projectFileSources = (await getProjectFileSources(projectId)).data;

        let recognizedWords =  /[\u00C0-\u1FFF\u2C00-\uD7FF\w\d]{3,}/g;

        _.each([ 'locationFragments', 'descriptionFragments' ], (collectionKey:string) => {
            let collection = completions[collectionKey], result:any = {};
            _.each(_.keys(collection), (key:string) => {
                let simplified = [], match;
                while (match = recognizedWords.exec(key)) {
                    simplified.push(key.substring(
                        match.index,
                        recognizedWords.lastIndex
                    ).toLowerCase());
                }
                result[simplified.join(' ')] = {
                    count: completions[collectionKey][key],
                    orig:  key
                };
            });
            completions[collectionKey] = result;
        });
        let projectItems = [
            {
                key: CommonStoreStateKeys.currentProject,
                value: currentProject
            },
            {
                key: CommonStoreStateKeys.inspections,
                value: inspections
            },
            {
                key: CommonStoreStateKeys.subcontractors,
                value: subcontractors
            },
            {
                key: CommonStoreStateKeys.polygonsAndCfgs,
                value: polygonsAndCfgs
            },
            {
                key: CommonStoreStateKeys.realWorldPlanTransformations,
                value: realWorldPlanTransformations
            },
            {
                key: CommonStoreStateKeys.recordPoints,
                value: recordPoints
            },
            {
                key: CommonStoreStateKeys.currentDefects,
                value: currentDefects
            },
            {
                key: CommonStoreStateKeys.completions,
                value: completions
            },
            {
                key: CommonStoreStateKeys.projectFiles,
                value: projectFiles
            },
            {
                key: CommonStoreStateKeys.currentStats,
                value: currentStats
            },
            {
                key: CommonStoreStateKeys.participants,
                value: participants
            },
            {
                key: CommonStoreStateKeys.attachmentSources,
                value: attachmentSources
            },
            {
                key: CommonStoreStateKeys.projectFileSources,
                value: projectFileSources
            },
        ]
        setProject(projectItems);
        updateCookie();

        let newWatchdog = {canUpdateLocalDefectDate: true};
        dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, newWatchdog, dataFromBackbone => {
            dispatch(setStateValue({
                key: CommonStoreStateKeys.watchdog,
                value: dataFromBackbone
            }))
        })
        versionCheck();
        localStorage.setItem(localStorageItem.OA_PROJECT, projectId);
        callFunctionInApp(APP_FUNCTION_NAME.SAVE_CUSTOMER_PROJECT, {customerProject: currentCustomer + "::" + projectId}, undefined);
        return true;
    }

    useEffect(()=>{
        (async () => {
            let customerPath = getCustomerIdFromCustomerProject(customerProject);
            let customerChanged = !!customerPath && customerPath !== currentCustomer;
            if(customerChanged) {
                dispatch(setStateValue({
                    key: CommonStoreStateKeys.currentCustomer,
                    value: customerPath
                }))
                return;
            }

            let pathProject = getProjectIdFromCustomerProject(customerProject);
            if(!!currentProject.id && currentProject.id === pathProject) {
                setIsLoading(false);
                return;
            }
            let [e, r] = await awaitWrap(fetchProject(pathProject!));

            if(!!e) {
                console.log('fetch project error', e)
                navigate("/error", {state:{error:'fetch project failed'}});
                return;
            }
            setIsLoading(false);
        })()
    }, [customerProject])


    useEffect(()=>{
        if(!isLoading) {
            let offlineQueueModel = getBackboneModel(CommonStoreStateKeys.offlineQueue);
            offlineQueueModel.online();
        }
    },[isLoading])

    return isLoading ?
        <Spinner/>
        :
        props.children

}

// @ts-ignore
const InspectionSetter: React.FC<DataSetterProps> = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const currentInspection = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentInspection]);
    const inspections = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.inspections]);
    const {inspectionId, customerProject} = useParams();

    useEffect(()=>{
        (async ()=>{
            let customerPath = getCustomerIdFromCustomerProject(customerProject);
            let projectPath = getProjectIdFromCustomerProject(customerProject);
            if((customerPath !== currentCustomer) || (projectPath !== currentProject.id)) {
                return;
            }
            if(!inspectionId) {
                navigate('/');
                return;
            }

            if(!!currentInspection.id && currentInspection.id === inspectionId) {
                setIsLoading(false);
                return;
            }
            setIsLoading(true);

            let inspection;
            let [e,r] = await awaitWrap(getCurrentInspection(inspectionId));

            if(!!e) {
                inspection = inspections.find((el:any) => el.id === inspectionId);
            } else {
                inspection = r?.data;
            }

            if(!inspection) {
                navigate('/error');
                return;
            }

            dispatchDataWithCallback(true, CommonStoreStateKeys.currentInspection, inspection, dataFromBackbone => {
                dispatch(setStateValue({key: CommonStoreStateKeys.currentInspection, value: dataFromBackbone}));
            })
            setIsLoading(false);
        })()
    }, [inspectionId])


    return isLoading ?
        <Spinner/>
        :
        props.children

}

const Router = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dialog, setDialog] = useState<DialogInfo | null>(null);
    let dispatch = useAppDispatch();
    let navigate = useNavigate();

    // @ts-ignore
    window.navigateCallback = (path:string, state: any)=>{
        navigate(path, {state});
    }

    window.onload = () => {
        if(window.location.pathname === '/onlineBauabnahme/error') {
            // @ts-ignore
            window.navigateCallback('/');
        }
    }

    // @ts-ignore
    window.saveBackboneDataInReact = (backboneData: any) => {
        let stateIdentifier = backboneData.reactStateIdentifier as CommonStoreStateKeys;
        let stateValue = backboneData.reactStateValue;
        dispatch(setStateValue({key: stateIdentifier, value:stateValue}));
    }

    useEffect(()=>{
        (async () => {
            callFunctionInApp(APP_FUNCTION_NAME.GET_LAST_MODIFIED, {});
            isIOSApp && callFunctionInApp("proaapp_onload", {});
            isAndroidApp && callFunctionInApp("oaAppOnLoad", {});

            if(!isWebApp) {
                callFunctionInApp(APP_FUNCTION_NAME.GET_INFO_FOR_STARTING_APP, {}, (res:any) => {
                    if(typeof res === "string") {
                        res = JSON.parse(res);
                    }
                    let appVersion = res.clientVersion;
                    if((isIOSApp && appVersion < appVersion.IOS) || (isAndroidApp && appVersion < appVersion.ANDROID)) {
                        showAppVersionOutdatedDialog();
                        return;
                    }
                    loadBackboneResources();
                }, () => {
                    showAppVersionOutdatedDialog();
                })
            } else {
                handleLocalstorageItemFromOtherServices((data, key)=>{
                    operateDataFromAnApplication(data, key);
                });
                loadBackboneResources();
            }
        })();

    },[]);

    async function loadBackboneResources() {
        require("./webapp/jquery.mobile-1.4.2.css");
        require("./webapp/jquery.mobile.icons.min.css");
        require("./webapp/leaflet.draw.css");
        require("./webapp/leaflet.css");
        require("./webapp/font.css");
        require("./webapp/jqmobile.ox.css");
        require("./webapp/c3.css");
        require("./webapp/main.css");
        require("./webapp/main-stylesheet.css");
        let [e0, r] = await jqueryDeferredWrap(require("./webapp/main"));
        if(!!e0){
            navigate("/error");
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
    }

    function showAppVersionOutdatedDialog() {
        setDialog({
            title: "Warnung",
            text: "Ihre App ist veraltet! Manche wichtige Funktionen sind deswegen nicht mehr verfügbar! Es wird dringend empfohlen, die App in App Store zu aktualisieren!",
            buttons: [
                {
                    label: "Nein",
                    callback: () => {
                        setDialog(null);
                        loadBackboneResources();
                    }
                },
                {
                    label: "OK",
                    callback: () => {
                        callFunctionInApp(APP_FUNCTION_NAME.GO_TO_APP_STORE, {});
                        setDialog(null);
                        loadBackboneResources();
                    }
                }
            ]
        })
    }

    const AfterLogin = () => {

        if (getTranslationsFailed()) {
            disableBeforeUnloadEvent();
            window.location.reload();

            //stuff after here is not executed, just as a fallback

            window.setTimeout(function() {
                //as last resort fallback. Do a 3000ms timer for not running in a race condition
                setTranslationsFailed(false); //don't run in a reload loop!
                navigate("/");
            }, 3000);
        } else {
            navigate("/");
        }
        return <></>
    }

    return ( !!dialog ?
            <SimpleChoiceDialog open={true} title={dialog!.title} text={dialog!.text} buttons={dialog!.buttons}/>
            :
            isLoading ?
                <Spinner/>
                :
            <AppStateProvider>
                <Routes>
                    <Route path={"/service"} element={<Navigate to={"/"}/>}/>
                    <Route path={"/"} element={<Navigate to={"/account"} state={{customerProjectAutoSelect: true}}/>}/>
                    <Route path={"/account"} element={
                        <UserSetter>
                            <Account/>
                        </UserSetter>
                    }/>
                    <Route path={"/news"} element={
                        <UserSetter>
                            <News/>
                        </UserSetter>
                    }/>
                    <Route path={"/help"} element={
                        <UserSetter>
                            <Help/>
                        </UserSetter>
                    }/>
                    <Route path={"/help/:helpTextId"} element={
                        <UserSetter>
                            <Help/>
                        </UserSetter>
                    }/>
                    <Route path={"/hotline"} element={
                        <UserSetter>
                            <Hotline/>
                        </UserSetter>
                    }/>
                    <Route path={"/queueTool"} element={
                        <UserSetter>
                            <QueueTool/>
                        </UserSetter>
                    }/>
                    <Route path={"/projects"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                {<ProjectSelection/>}
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject"}
                           element={
                               <UserSetter>
                                   <ProjectsSetter>
                                       <ProjectSetter>
                                           {<Project/>}
                                       </ProjectSetter>
                                   </ProjectsSetter>
                               </UserSetter>
                           }/>
                    <Route path={"/project/:customerProject/inspections"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Inspections/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <NewInspection/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Inspection/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/edit"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <NewInspection/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/defects"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Defects/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/defects/:uuid?"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Defects/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/defects/requestShare/:shareToken"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Defects/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/defect/:defectPrefixId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Defect/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/defect/:defectPrefixId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Defect/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/location"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Location/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/location"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Location/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/files"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Files/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/files"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Files/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/image"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Image/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/image"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Image/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/documents"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Documents/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/documents"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Documents/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/requestBundle"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <RequestBundle/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/requestBundle"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <RequestBundle/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/subcontractors"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Subcontractors/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/subcontractors"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Subcontractors/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/subcontractor"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Subcontractor/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/subcontractor"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Subcontractor/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/subcontractor/:subcontractorId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Subcontractor/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/subcontractor/:subcontractorId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Subcontractor/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/subcontractorBundle/:subcontractorId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <SubcontractorBundle/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/subcontractorBundle/:subcontractorId"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <SubcontractorBundle/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/cost"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Cost/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/cost"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Cost/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/selectInspection"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <SelectInspection/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/selectInspection"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <SelectInspection/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/principalBundle"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <PrincipalBundle/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/principalBundle"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <PrincipalBundle/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/instantMessage"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <InstantMessage/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/instantMessage"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InstantMessage/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/paperwork"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Paperwork/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/paperwork"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Paperwork/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/statistics"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Statistics/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/statistics"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Statistics/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/inspection/:inspectionId/filter"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <InspectionSetter>
                                        <Filter/>
                                    </InspectionSetter>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/filter"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Filter/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/settings"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <Settings/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/personCSV"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <PersonCSV/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/buyerAccess"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <BuyerAccess/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/buyerRoles"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <BuyerRoles/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/project/:customerProject/warrantyDataOverview"} element={
                        <UserSetter>
                            <ProjectsSetter>
                                <ProjectSetter>
                                    <WarrantyDataOverview/>
                                </ProjectSetter>
                            </ProjectsSetter>
                        </UserSetter>
                    }/>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/afterlogin"} element={<AfterLogin />}/>
                    <Route path={"/logout"} element={<Navigate to="/login" state={{fromLogout: true}}/>}/>
                    <Route path={"/error"} element={<ErrorPage/>}/>
                    <Route path={"/forceCustomerProject/:customer/:project"} element={<ForceCustomerProject/>}/>
                    <Route path="*" element={<ErrorPage/>}/>
                </Routes>
            </AppStateProvider>
    )
}



export default Router;
