define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = '<div class="reduced-defects-banner-inner-container">' +
            '<span class="reduced-defects-banner-icon">%%icon</span>\n' +
            '<span class="reduced-defects-banner-text"><%= t("reduceddefects.msg") %></span> \n' +
            '<button class="reduced-defects-banner-button-more"><%= t("reduceddefects.more") %></button> \n' +
        '</div>';
    return page;
});
