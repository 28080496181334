define([
	'../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../model/attachmentSources', '../model/currentProject',
	'../helper/offlineQueue', 'moment', '../helper/watchdog', '../helper/idutil', '../model/subcontractors'
], function (
	_, Backbone, user, attachmentSources, currentProject,
	offlineQueue, moment, watchdog, idutil, subcontractors
) {
	var Defect = window.Defect = Backbone.Model.extend({ //assign to global variable for usage in offlineQueue

		idAttribute: 'projectId',

		defaults: function () {
			return {
				inspections: [],
				location: [],
				attachments: [],
				status: 'fix',
				sa: 0,
				subcontractorStatus: 'pending',
				principalStatus: 'pending',
				type: 'technical',
				isFavourite: false,
				external: false,
				clientId: Defect.generateUUID()
			};
		},

		urlRoot:  '/onlineBauabnahme/api/defect',

		parse: function (data) {
			if (!data.attachments && this.has('attachments')) {
				delete data.attachments;
			}
			return data;
		},

		url: function() {
			var base =
				_.result(this, 'urlRoot') ||
				_.result(this.collection, 'url') ||
				urlError();
			if (this.isNew()) return base;
			return base.replace(/([^\/])$/, '$1/') + encodeURIComponent(this.get('id'));
		},

		initialize: function () {
			this.listenTo(this, 'change:location', this.updateLocationId);
			this.listenTo(this, 'sync', function () {
				//track added attachment sources
				try { //exception here would be very bad
					var sources = this.get('attachmentSources');
					if (sources) {
						_.each(sources, function (source) {
							var attachmentSource = attachmentSources.get(source.id);
							if (!attachmentSource) {
								return;
							}
							attachmentSource.set('defect', this.get('projectId'));
						}, this);
					}
				} catch (e) {
				}
			});
		},

		sync: function (method, model, options) {
			if (options.noExtensions) {
				return Backbone.sync.apply(Backbone, arguments);
			}
			return offlineQueue.sync.apply(offlineQueue, arguments);
		},

		save: function () {
			var result = Backbone.Model.prototype.save.apply(this, arguments);
			if (watchdog.get('defectLocalDate') == watchdog.get('defectDate')) {
				watchdog.set('canUpdateLocalDefectDate', true);
			}
			return result;
		},

		updateLocationId: function () {
			var location = this.get('location');
			this.set('locationId', _.map(location.slice(0, location.length - 1), function (name) {
				return Defect.toId(name);
			}, this).join('-'));
		},

		getIdLabel: function (forcePrincipalMode, addPrincipalId) {
			forcePrincipalMode = forcePrincipalMode || false;
			if (user.isPrincipal() || forcePrincipalMode) {
				if (this.get('externalId')) {
					return this.get('externalId');
				}
			} else {
				if (this.get('projectId')) {
					var settings = user.get('settings');
					if (addPrincipalId) {
						return 'N' + this.get('projectId') + ' (' + (this.get('external') ? this.get('externalId') : '-') + ')';
					} else {
						return 'N' + this.get('projectId');
					}
				}
			}
			return null;
		},

		clone: function () {
			var result = Backbone.Model.prototype.clone.apply(this, arguments);
			_.each([ 'location', 'inspections', 'attachments' ], function (key) {
				result.set(key, _.cloneDeep(this.get(key)));
			}, this);
			return result;
		},

		getLabel: function (forcePrincipalMode, addPrincipalId) {
			forcePrincipalMode = forcePrincipalMode || false;
			var label = '';
			var idLabel = this.getIdLabel(forcePrincipalMode, addPrincipalId);
			if (idLabel) {
				label += idLabel + '. ';
			}
			var location = this.get('location');
			if (location) {
				label += _.compact(location).join(', ');
			}
			var description = this.get('description');
			if (description) {
				label += ': ';
				label += description;
			}
			if (this.get('subcontractorStatus') === 'fixed') {
				label += ' (' + user.translate('defect.subcontractorfixed') + ')';
			}
//			if (!this.isStatusPending()) {
//				label += ' (erledigt)';
//			}
			return label;
		},

		getLabelHTML: function (forcePrincipalMode, addPrincipalId) {
			forcePrincipalMode = forcePrincipalMode || false;
			var label = '';
			var defectId = '<span class="defect-id">' + this.getIdLabel(forcePrincipalMode, addPrincipalId) + '</span>';

			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.get('type');
			}.bind(this));

			var style;
			if (defectType) {
				style = 'color: ' + defectType.textColor + '; background-color: ' + defectType.backgroundColor + (!!defectType.borderColor ? '; border: 1px solid ' + defectType.borderColor + "; " : '') + (defectType.isRounded ? ' border-radius:5px;' : '');
			}
			var defectTypeIcon = '<span class="defect-type-indicator defect-type-'+ (defectType ? defectType.defectTypeId :"unknown") +'" style="' + style + '">' + (defectType ? defectType.content : "") + '</span>';
			var defectLocation = '<span class="defect-location">' + _.compact(this.get('location')).join(', ') + '</span>';
			var defectDescription = '<span class="defect-description">' + (this.get('description') ? this.get('description') : '') + '</span>';
			label += '<div class="defect-data">'+ defectId + defectTypeIcon + defectLocation + defectDescription + '</div>';
			var defectCreationDate = '<span class="defect-creation-date">' + moment(this.get('created')).format('DD.MM.YYYY') + '</span>';
			var actualisationDate = moment(this.get('modified')).format('DD.MM.YYYY');
			var defectLastActualisationDate = '<span class="defect-last-actualisation-date">' + (actualisationDate === 'Invalid date' ? '-' : actualisationDate) + '</span>';
			var createdByName = this.get('createdBy');
			if(createdByName) createdByName = createdByName.split("(")[0];
			var defectCreator;
			// if(_.contains(createdByName, " ")){
			// 	var names = createdByName.split(" ");
			// 	var firstname = names[0];
			// 	var lastname = names[1];
			// 	defectCreator = '<span class="defect-creator">' + firstname + '<br />' + lastname + '</span>';
			// } else {
				defectCreator = '<span class="defect-creator" title="'+ createdByName +'">' + createdByName + '</span>';
			// }
			var defectDeadline = this.get('deadline');
			defectDeadline = defectDeadline == null ? "-" : moment(defectDeadline).format('DD.MM.YYYY');
			var expired = moment().diff(moment(this.get('deadline')), 'days') > 0;
			defectDeadline = '<span class="defect-deadline-date" ' + (expired === true ? 'style="color: red;"' : + '') +'>' + defectDeadline + '</span>';
			var sub = ((user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) ? (this.get('crew') ? this.get('crew') : '-') : (subcontractors.get(this.get('subcontractor')) ? subcontractors.get(this.get('subcontractor')).get('name') : '-'));
			var defectSubcontractor = '<span class="defect-subcontractor" title="' + sub + '">' + sub + '</span>';
			var typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(this.get('type'));
			var escalationlevel = this.get('requests');
			escalationlevel = (escalationlevel === 4 ? (typeConfig.terminationLevelName.length > 20 ? 4 : typeConfig.terminationLevelName) : escalationlevel);
			var escalationlevelLong = (escalationlevel === 4 ? typeConfig.terminationLevelName : escalationlevel);
			var defectEscalationlevel = '<span class="defect-escalationlevel" title="' + escalationlevelLong + '">' + escalationlevel + '</span>';
			var defectFixedDate = '<span class="defect-fixed-date">' + (this.get('fixed') ? moment(this.get('fixed')).format('DD.MM.YYYY') : '-') + '</span>';
			var settings = user.get('settings');
			label += '<div class="defect-metadata">' +
				(settings.defectMetadata.defect_show_creator ? defectCreator : "") +
				(settings.defectMetadata.defect_show_subcontractor ? defectSubcontractor : "") +
				(settings.defectMetadata.defect_show_creation_date ? defectCreationDate : "") +
				(settings.defectMetadata.defect_show_actualisation_date ? defectLastActualisationDate : "") +
				// (settings.defectMetadata.defect_show_fixed_date ? defectFixedDate : "") +
				((!user.isPrincipal() && settings.defectMetadata.defect_show_escalationlevel) ? defectEscalationlevel : "") +
				(settings.defectMetadata.defect_show_deadline ? defectDeadline : "") +
			'</div>';
			return label;
		},

		getStatusId: function () {
			var status = '';
			if (this.get('external') && this.get('status') === 'fixed' && this.get('principalStatus') === 'pending' && this.get('type') !== 'obstruction2' && currentProject.get('showYellowAgPins')) {
				status = 'agPending';
			} else if (this.get('requests') === 4) {
				status = 'terminated';
			} else {
				status = this.get('status');
			}
			return status;
		},

		getStatusText: function () {
			var key = this.getStatusId();
			return {
				irrelevant: user.translate('defect.status.irrelevant'),
				determined: user.translate('defect.status.determined'),
				fix:        user.translate('defect.status.fix'),
				open:       user.translate('defect.status.open'),
				discount:   user.translate('defect.status.discount'),
				fixed:      user.translate('defect.status.fixed'),
				pending:    user.translate('defect.status.pending'),
				rejected:   user.translate('defect.status.rejected'),
				observed:   user.translate('defect.status.observed'),
				agPending:  user.translate('defect.status.inExaminationByAg'),
				terminated: user.translate('defect.status.terminated')
			}[key];
		},

		isStatusPending: function () {
			var status = this.get('status');
			return (!status || status === 'fix' || status === 'open' || status === 'observed') && this.get('requests') !== 4;
		},

		belongsTo: function (unitPath) {
			if (!this.has('locationId')) {
				this.updateLocationId();
			}
			var locationId = this.get('locationId') || '';
			return locationId === unitPath || locationId.indexOf(unitPath + '-') === 0;
		},

		canVerify: function () {
			var status = this.get('status');
			var isUser = !user.isSubcontractor() && !user.isPrincipal();
			
			var isPrincipal = user.isPrincipal();
			var principalStatus = this.get('principalStatus');
			
			var isSubcontractor = user.isSubcontractor();
			var subcontractorStatus = this.get('subcontractorStatus');

			var isRestrictedSubcontractor = user.isRestrictedSubcontractor();

			var canVerify;
			if(isUser) {
				canVerify = this.get('requests') === 4 ? 'terminateduser' : ((status === 'irrelevant' || status === 'fixed' || status === 'rejected') ? 'user' : false);
			} else if (isPrincipal) {
				canVerify = this.get('requests') === 4 ? 'terminatedprincipal' : (principalStatus === 'accepted' ? 'principal' : false);
			} else if (isRestrictedSubcontractor) {
				canVerify = false;
			} else if (isSubcontractor) {
				canVerify = this.get('requests') === 4 ? 'terminatedsubcontractor' : (subcontractorStatus === 'fixed' ? 'subcontractor' : false);
			}
			return canVerify;
			// return (!user.isSubcontractor() && !user.isPrincipal() && (status === 'irrelevant' || status === 'fixed' || status === 'rejected')) || (user.isSubcontractor() && this.get('subcontractorStatus') === 'fixed');
		},

		getCombinedStatusIcon: function () {
			var filename = [], status = this.get('status'), statusAccepted = this.get('sa'), subcontractorStatus = this.get('subcontractorStatus'), subcontractorComment = this.get('acs'), principalStatus = this.get('principalStatus');

			var base = (status === 'fixed' && (!!statusAccepted || (this.get('external') && principalStatus === 'pending' && currentProject.get('showYellowAgPins'))) ? 'accepted' : status);
			var baseExt = ((status === 'fixed' && this.get('es') === 'P') ? '2' : ''); //for fixed AND accepted

			filename.push(base + baseExt);
			filename.push(principalStatus + ((principalStatus === 'rejected' && this.get('es') === 'G') ? '2' : ''));
			if (subcontractorStatus === 'pending') {
				filename.push(subcontractorComment === 'S' ? 'comment' : subcontractorStatus);
			} else {
				filename.push(subcontractorStatus + ((subcontractorStatus === 'fixed' && this.get('as') === 'G') ? '2' : ''));
			}

			//if (this.get('isFavourite')) {
			//	filename.push('fav');
			//}
			
			if(this.get('requests') === 4) {
				filename = ['terminated'];
			}

			return filename.join('-') + (this.get('requests') === 4 ? '.svg' : '.png');
		}

	}, {
		//STATIC METHODS ======================================================

		toId: function (part) {
			return idutil.locationPartToId(part);
			//return part.toLowerCase().replace(/[^a-z0-9\u4E00-\u9FA5]/g, '_');
		},

		generateUUID: function () {
			var d = new Date().getTime();
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d/16);
				return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});
		},

		generateCreationDate: function () {
			return moment().format('YYYY-MM-DD HH:mm:ss');
		}

	});
	return Defect;
});
