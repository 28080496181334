define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function (
    $, _, Backbone
) {

    let _internalState = {
        updateCallbacks: [],

        translationsFailed: null,
        usingReducedDefectList: null,
        hideReducedDefectListBanner: false
    };

    const triggerCallbacks = function() {
        try {
            _internalState.updateCallbacks.forEach((i) => {
                try {
                    i.apply();
                } catch(e) {
                    console.log("callback failed", e);
                }
            });
        } catch(e) {
            console.log("callback failed", e);
        }
    };

    //It communicates the state between the backbone and the react world
    var appStateComm = {

        //If you are not logged in, loading translations will fail. This saves the information whether translations are loaded or not
        getTranslationsFailed: function () { return _internalState.translationsFailed; },
        setTranslationsFailed: function (value) {
            const changed = (value !== _internalState.translationsFailed);
            _internalState.translationsFailed = value;
            if (changed) {
                triggerCallbacks();
            }
        },

        //if there are a lot of defects, the UI is very slow (>40k defects). This saves the information whether we use a reduced set or all defects
        getUsingReducedDefectList: function () { return _internalState.usingReducedDefectList; },
        setUsingReducedDefectList: function (value) {
            const changed = (value !== _internalState.usingReducedDefectList);
            _internalState.usingReducedDefectList = value;
            if (changed) {
                triggerCallbacks();
            }
        },

        getHideReducedDefectListBanner: function() { return _internalState.hideReducedDefectListBanner; },
        setHideReducedDefectListBanner: function (value) {
            const changed = (value !== _internalState.hideReducedDefectListBanner);
            _internalState.hideReducedDefectListBanner = value;
            if (changed) {
                triggerCallbacks();
            }
        },

        //In the browser there is always that popup whether you really want to reload the page, changes are lost. This deactivates that message if you want to reload the page.
        disableBeforeUnloadEvent: function() {
            $(window).off('beforeunload') ;
        },

        registerCallback: function(callback) {
            _internalState.updateCallbacks.push(callback);
        }

    };

    window.debugAppStateComm = appStateComm;

    return appStateComm;
});
