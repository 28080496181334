import axios from "axios";
import {CONTEXT_PATH} from "../index";

export const addContextPathPrefix = (api: string): string => {
    return `${CONTEXT_PATH}${api}`;
}

export const versionPing = async (latency: number, projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/version?l=${latency}&p=${projectId}`));
}

export const getUser = async () => {
    return axios.get(addContextPathPrefix("/api/user"));
}

export const login = async (experienceId: string, password: string) => {
    return axios.put(
        addContextPathPrefix(`/api/user`),
        {
            experienceId,
            password
        });
}

export const getProxyInfo = async () => {
    return axios.get(addContextPathPrefix("/api/proxyConfig"));
}

export const getMaAccounts = async () => {
    return axios.get("/myaccount/api/services");
}

export const inviteOldSubcontractor = async () => {
    return axios.post("/api/inviteOldSubcontractor");
}

export const getProjects = async () => {
    return axios.get(addContextPathPrefix("/api/projects"));
}

export const getHelp = async () => {
    return axios.get(addContextPathPrefix("/api/help"));
}

export const getAccessibleAccounts = async () => {
    return axios.get(addContextPathPrefix("/api/accessibleAccounts"));
}

export const getCurrentProject = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/project/${projectId}`));
}

export const getInspections = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/inspections/${projectId}`));
}

export const getSubcontractors = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/subcontractors/${projectId}`));
}

export const getPolygonsAndCfgs = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/polygons/${projectId}`));
}

export const getRealWorldPlanTransformations = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/project/${projectId}/realWorldPlanTransformation`));
}

export const getRecordPoints = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/recordPoints/${projectId}`));
}

export const getCurrentDefects = async (projectId: string, reduced: boolean) => {
    return axios.get(addContextPathPrefix(`/api/defects/${projectId}?${reduced ? 'reduced=true' : ''}`));
}

export const getCompletions = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/completions/${projectId}`));
}

export const getProjectFiles = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/filesAll/${projectId}`));
}

export const getCurrentInspection = async (inspectionId: number | string) => {
    return axios.get(addContextPathPrefix(`/api/inspection/${inspectionId}`));
}

export const getCurrentStats = async (projectId: string, externalOnly: boolean, subcontractorId?: string) => {
    return axios.get(addContextPathPrefix(`/api/projectStats/${projectId}?externalOnly=${externalOnly}`));
}

export const getParticipants = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/participant/${projectId}`));
}

export const getAttachmentSources = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/attachment-sources/${projectId}`));
}

export const getProjectFileSources = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/file-sources/${projectId}`));
}

export const postCustomer = async (customerId: string) => {
    return axios.post(addContextPathPrefix(`/api/myaccount/oa/${customerId}`));
}

export const postBackupProject = async (projectId: string, email: string) => {
    return axios.postForm(addContextPathPrefix(`/api/request-backup`), {project: projectId, email});
}

export const postUploadProjectImage = async (customerId: string, projectId: string, data: string) => {
    return axios.post(`/teams/sync/customers/${customerId}/projects/${projectId}/image`, {data: data, services: ['oa', 'fd2', 'ot2', 'qm']});
}

export const getTrackedPaperwork = async (projectId: string, subcontractor?: string) => {
    return axios.get(addContextPathPrefix('/api/trackedPaperworkInfo?project=' + projectId + '&subcontractor=' + (!!subcontractor ? subcontractor : '__ALL__')));
}

export const saveProjectSettings = async (projectId: string, settings: any) => {
    let lm = +new Date();
    return axios.post(addContextPathPrefix(`/api/projectmanager/projects/${projectId}?lastModified=${lm}`), settings);
}

export const saveUserSettings = async (settings: any) => {
    return axios.put(addContextPathPrefix(`/api/settings`), {settings});
}

export const deleteShareTokens = async (projectId: string) => {
    return axios.delete(addContextPathPrefix(`/api/deleteShareTokens/${projectId}`));
}

export const getAdminProject = async (projectId:string) => {
    return axios.get(addContextPathPrefix(`/api/projectmanager/project/${projectId}`));
}

export const getPlanSyncSize = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/files/sync?project=${projectId}`));
}

export const getAttachmentSyncSize = async (projectId: string) => {
    return axios.get(addContextPathPrefix(`/api/defects/${projectId}/modified`));
}

export const getSubcontractorRequestInfo = async (token: string) => {
    return axios.get(addContextPathPrefix(`/api/getSubcontractorRequestInfo/${token}`));
}
