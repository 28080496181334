define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', './ReducedDefectsBannerHtml',
    '../model/user',
    './Popup', '../helper/watchdog', '../helper/offlineQueue', '../helper/iosapp', '../helper/appStateComm'
], function (
    $, _, Backbone, moment, Template,
    user,
    Popup, watchdog, offlineQueue, iosapp, appStateComm
) {
    const icon = '<svg focusable="false" viewBox="0 0 24 24"><path d="M4 10c0 .55.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1m10.79-6H13V2c0-.55-.45-1-1-1s-1 .45-1 1v2H9.21c-.45 0-.67.54-.36.85l2.79 2.79c.2.2.51.2.71 0l2.79-2.79c.32-.31.1-.85-.35-.85M9.21 19H11v2c0 .55.45 1 1 1s1-.45 1-1v-2h1.79c.45 0 .67-.54.35-.85l-2.79-2.79c-.2-.2-.51-.2-.71 0l-2.79 2.79c-.31.31-.09.85.36.85M5 14h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1"></path></svg>';
    const iconWrapped = '<span style="display: inline-block; height: 1em; width: 1em;">' + icon + '</span>';

    console.log('ffee1');

    var ReducedDefectsBanner = function() {
    };
    ReducedDefectsBanner.prototype.replaceIcon = function(str) {
        return str.replaceAll('%%iconWrapped', iconWrapped).replaceAll('%%icon', icon);
    };
    ReducedDefectsBanner.prototype.renderReducedDefectsBanner = function($root, renderFunction) {
        const innerContainer = $('.reduced-defects-banner-inner-container', $root);
            if (!this.appCommCallbackRegistered) {
                this.appCommCallbackRegistered = true;
                console.log('ffee2');
                appStateComm.registerCallback(() => {
                    renderFunction && renderFunction();
                });
            }

            //don't use show/hide, this is buggy in the inspection view, where there's a hack to integrate the defects view
            $root.empty();
            if (appStateComm.getUsingReducedDefectList() && !appStateComm.getHideReducedDefectListBanner()) {
                $root.append(this.replaceIcon(_.template(Template, { t: user.translate })));
                $root.click(function(e) { //not only the button, but all elements, also the text
                    e.preventDefault();
                    e.stopPropagation();

                    var popup = new Popup({ header: user.translate('popup.title'), messageHtml: this.replaceIcon(user.translate('reduceddefects.msg')), buttons: [ {
                            text: user.translate('reduceddefects.notShowAgain'),
                            event: 'notShow',
                            close: true
                        }, 'ok' ] }).render();
                    popup.on('notShow', function () {
                        popup.closedDeferred.done(function () {
                            appStateComm.setHideReducedDefectListBanner(true);
                        }.bind(this));
                    }.bind(this) );

                }.bind(this));

                //$root.show();
            } else {
                //$root.hide();
            }

    };

    return ReducedDefectsBanner;

});