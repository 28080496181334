import Box from "@mui/material/Box";
import {translate} from "../../../utils/utils";
import {SettingsDescriptionWithMarginTop, SettingsSwitch} from "../SettingsComponents";
import {IconButton, Switch} from "@mui/material";
import React, {useState} from "react";
import {DocumentGenerationConfiguration} from "./types/types";
import useDocumentGenerationCommentsSetting from "./hooks/useDocumentGenerationCommentsSetting";
import useCommentCheckedSetting from "./hooks/useCommentCheckedSetting";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DocumentGenerationCommentsConfigurationHelp from "./DocumentGenerationCommentsConfigurationHelp";

type DocumentGenerationCommentsConfigurationProps = {
    updateProjectSettings: (settings: { documentGenerationConfiguration: DocumentGenerationConfiguration }) => void;
    setRef: (name: string, node: HTMLElement | null | unknown) => void;
    newProjectSettings: { documentGenerationConfiguration: DocumentGenerationConfiguration };
}
const DocumentGenerationCommentsConfiguration = ({
                                                     newProjectSettings,
                                                     updateProjectSettings,
                                                     setRef
                                                 }: DocumentGenerationCommentsConfigurationProps) => {

    const [handleSuppressCommentsChange, handleSuppressSubcontractorCommentsChange] = useDocumentGenerationCommentsSetting(newProjectSettings, updateProjectSettings);
    const [shouldSuppressCommentsChecked, shouldSuppressSubcontractorCommentsChecked] = useCommentCheckedSetting(newProjectSettings);
    const [showCommentsHelp, setShowCommentsHelp] = useState<boolean>(false);

    return (
        <Box
            aria-details={`${translate("settings.customer.title")}-${translate("settings.documentcreation")}`}
            ref={node => setRef('userSettingsDocument2', node)}>

            <SettingsSwitch
                control={
                    <Switch
                        onChange={e => handleSuppressCommentsChange(e.target.checked)}
                        checked={shouldSuppressCommentsChecked}
                    />}
                label={translate('settings.documentGenerationConfiguration.shouldSuppressComments')}
            />

            <SettingsSwitch
                control={
                    <Switch
                        onChange={e => handleSuppressSubcontractorCommentsChange(e.target.checked)}
                        checked={shouldSuppressSubcontractorCommentsChecked}
                    />}
                label={translate('settings.documentGenerationConfiguration.shouldSuppressSubcontractorComments')}
                style={{margin: '10px 0 0 0'}}
            />
            <SettingsDescriptionWithMarginTop>{translate("settings.documentGenerationConfiguration.suppressComments.hint")}
                <IconButton onClick={() => setShowCommentsHelp(true)}
                            style={{width: 'auto', backgroundColor: 'white'}}>
                    <InfoOutlinedIcon sx={{position: 'absolute', left: '.2rem'}}/>
                </IconButton>
            </SettingsDescriptionWithMarginTop>

            <DocumentGenerationCommentsConfigurationHelp open={showCommentsHelp}
                                                         onClose={() => setShowCommentsHelp(false)}/>
        </Box>

    )
}

export default DocumentGenerationCommentsConfiguration;