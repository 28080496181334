define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='subcontractors-page-header'>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\t<div class=\"reducedDefectsBanner\"></div>\n" +
        "\n" +
        "\t<p class=\"subcontractors-noassign-hint\" style=\"display: none\"><span><%- t('subcontractors.noassign.hint') %></span> <button class=\"goto-noassign ui-btn ui-icon-arrow-r ui-btn-icon-notext ui-btn-inline ui-corner-all\" style=\"margin: 0\"></button></p>\n" +
        "\n" +
        "\t<p><%- t('subcontractors.legend') %></p>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" class=\"list defectButtonsList\"></ul>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"actionsmenu\">\n" +
        "\t<ul data-role=\"listview\">\n" +
        "\t<li data-role=\"list-divider\"><%= t('subcontractors.orderby') %></li>\n" +
        "\t<li class=\"group\" style=\"padding: 0\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" style=\"margin: 0\">\n" +
        "\t\t<label><input type=\"radio\" name=\"order\" value=\"standard\"> <%= t('subcontractors.orderby.standard') %></label>\n" +
        "\t\t<label><input type=\"radio\" name=\"order\" value=\"total\"> <%= t('subcontractors.orderby.total') %></label>\n" +
        "\t\t<label><input type=\"radio\" name=\"order\" value=\"pending\"> <%= t('subcontractors.orderby.pending') %></label>\n" +
        "\t\t</fieldset>\n" +
        "\t</li>\n" +
        "\t<li class=\"defect-types\" data-role=\"list-divider\"><%= t('subcontractors.alloftype') %></li>\n" +
        "\t\t<li><a class=\"createall alldefects\"         style=\"border-top: none\"><span class=\"defect-type-indicator defect-type-alldefects\"         ></span> <%= t('defect.type.alldefects'         ) %></a></li>\n" +
        "\t\t<li data-role=\"list-divider\"><%= t('subcontractors.morerequests') %></li>\n" +
        "\t\t<li><a class='select-inspection'><%= t('subcontractors.inspectionrequests') %></a></li>\n" +
        "\t</ul>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='subcontractors-page-footer' data-position=\"fixed\"></div>";
    return page;
});