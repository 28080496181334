import React from 'react';
import {Box, TextField} from '@mui/material';
import {translate} from "../../../utils/utils";
import {SettingsDescriptionWithMarginTop} from "../SettingsComponents";
import {useCostThresholdInput} from "./hooks/useCostThresholdInput";

interface CostThresholdSetterProps {
    value: string | null;
    onChange: (value: string | null) => void;
    setRef: (name: string, node: HTMLElement | null | unknown) => void;
}

const CostThresholdSetter = ({
                                 value,
                                 onChange,
                                 setRef
                             }: CostThresholdSetterProps) => {

    const {handleInputChange} = useCostThresholdInput(onChange);


    return (
        <Box
            aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
            ref={node => setRef("projectSettingsMaxDefectCostAlertThreshold", node)}>
            <TextField
                variant="filled"
                label={translate('settings.maxDefectCostAlertThreshold.title')}
                placeholder={translate("settings.MaxDefectCostAlertThreshold.placeholder")}
                value={value || ''}
                onChange={handleInputChange}
                multiline
            />
            <SettingsDescriptionWithMarginTop>
                {translate("settings.maxDefectCostAlertThreshold.hint")}
            </SettingsDescriptionWithMarginTop>
        </Box>
    );
};

export default CostThresholdSetter;
