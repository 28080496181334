define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='location-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "<!--\n" +
        "\t<div class=\"location\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t<button class=\"parent ui-btn ui-btn-icon-left ui-icon-carat-l\">vorherige Ebene</button>\n" +
        "\t\t<input class=\"path\" readonly=\"readonly\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t</div>\n" +
        "-->\n" +
        "\t<div class=\"reducedDefectsBanner\"></div>\n" +
        "\t<ul class=\"units defectButtonsList\" data-role=\"listview\"></ul>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='location-page-footer' data-position=\"fixed\"></div>";
    return page;
});