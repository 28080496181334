define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../helper/iosapp',
	'../model/proxyInfo'
], function (
	$, _, Backbone, iosapp,
	proxyInfo
) {
	return new (Backbone.Model.extend({

		idAttribute: 'experienceId',

		defaults: {
			projects: [],
			translations: {
				'popup.connectionerror':         'Diese Funktion benötigt eine Internetverbindung. Erneut versuchen?',
				'popup.default.ok':              'OK',
				'popup.default.cancel':          'Abbrechen',
				'popup.homescreenwarning':       'Willkommen beim Service Bau-Mängel!\n\nAchtung: Die Anwendung wurde vom Home-Bildschirm / im Vollbild gestartet. Bitte nutzen Sie kein Multi-Tasking während einer Begehung, insbesondere bei instabiler Internetverbindung.\n\nEs wird empfohlen, den Service Bau-Mängel über einen Web-Browser wie Safari, Chrome oder Firefox zu starten.',
				'status.synced':                 'Daten wurden übertragen.',
				'status.connected':              'verbunden',
				'status.disconnected':           'getrennt',
				'popup.enforceoffline.confirm':  'Offline-Modus aktivieren?',
				'status.offline':                'antippen zum Verbinden',
				'popup.title':                   'Achtung',
				'popup.ok':                      'OK'
			},
			settings: {}
		},

		url: function () {
			 return  '/onlineBauabnahme/api/user';
		},

		initialize: function () {
			this.translate = _.bind(this.translate, this);
		},

		save: function (attrs, options) {
	        options || (options = {});

	        // Filter the data to send to the server
	        options.type = 'PUT';
	        options.data = JSON.stringify(_.omit(attrs, [ 'authorities', 'customer', 'customerLink', 'dataImport', 'department', 'email', 'inspections', 'name', 'passwordUnchanged', 'phone', 'projects', 'tiles', 'translations' ]));
	        options.contentType = 'application/json';

	        // Proxy the call to the original save function
	        return Backbone.Model.prototype.save.call(this, attrs, options);
	    },

		sync: function (method, model, options) {
			return Backbone.sync.apply(this, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		login: function (username, password) {
			var wasNew = this.isNew();
			this.clear({ silent: true });
			var result = this.save({
				experienceId: username,
				password: password
			}).then(function () {

				if ((typeof Storage !== 'undefined' && navigator.cookieEnabled)) {
					localStorage.setItem('cookie', document.cookie);
				}
			}, _.bind(function (cause) {
				if (wasNew) {
					this.unset('experienceId', { silent: true });
					this.set(_.result(this, 'defaults'), { silent: true });
				}
				if (cause == 'offline') {
					$.mobile.loading('hide');
					return window.confirmPopup(this.translate('popup.connectionerror')).then(_.bind(function () {
						$.mobile.loading('show');
						return this.login(username, password);
					}, this), function () {
						return $.Deferred().reject(cause);
					}).promise();
				}
				return $.Deferred().reject(cause);
			}, this));
			if (wasNew) {
				var other = $.Deferred();
				result.then(function () {
					if ((typeof Storage !== 'undefined' && navigator.cookieEnabled)) {
						localStorage.setItem('preventFullscreenWarning', true);
					}
					window.location.reload();
				}, function (cause) {
					other.reject(cause);
				});
				return other;
			}
			return result.promise();
		},

		isAdmin: function () {
			var authorities = this.get('authorities');
			return _.intersection(authorities, [ 'ROLE_ADMIN', 'ROLE_PROJECTADMIN' ]).length > 0;
		},

		isFullAdmin: function () {
			var authorities = this.get('authorities');
			return _.intersection(authorities, [ 'ROLE_ADMIN' ]).length > 0;
		},

		isSubcontractor: function () {
			return _.intersection(this.get('authorities'), ['ROLE_SUBCONTRACTOR', 'ROLE_SUBCONTRACTOR_READONLY']).length > 0;
		},

		isPrincipal: function () {
			return _.intersection(this.get('authorities'), [ 'ROLE_PRINCIPAL', 'ROLE_PRINCIPAL_READONLY' ]).length > 0;
		},

		isReadonly: function () {
			return _.intersection(this.get('authorities'), [ 'ROLE_SUBCONTRACTOR', 'ROLE_SUBCONTRACTOR_READONLY', 'ROLE_PRINCIPAL_READONLY', 'ROLE_READONLY' ]).length > 0;
		},

		isRestrictedSubcontractor: function () {
			return _.intersection(this.get('authorities'), ['ROLE_SUBCONTRACTOR_READONLY']).length > 0;
		},

		isMyAccountUser: function () {
            return _.intersection(this.get('authorities'), [ 'ROLE_MYACCOUNT_USER']).length === 1;
		},

		isOldSubcontractor: function () {
			return _.intersection(this.get('authorities'), [ 'ROLE_OLD_SUBCONTRACTOR']).length === 1;
		},

		translate: function (key) {
			var translations = this.get('translations');
			if (translations && key in translations) {
				return translations[key] || '';
			}
			return key;
		},

		getPrimaryEmail: function () {
			return (this.get('email') || '').split(/[,;\s]+/)[0];
		},

	}))();
});