import {DocumentGenerationConfiguration} from "../types/types";
import {useCallback} from "react";

const useDocumentGenerationCommentsSetting = (
    newProjectSettings: { documentGenerationConfiguration: DocumentGenerationConfiguration },
    updateProjectSettings: (settings: { documentGenerationConfiguration: DocumentGenerationConfiguration }) => void
) => {
    const handleSuppressCommentsChange = useCallback((value: boolean) => {
        updateProjectSettings({
            documentGenerationConfiguration: {
                ...newProjectSettings.documentGenerationConfiguration,
                shouldSuppressComments: value,
            },
        });
    }, [newProjectSettings, updateProjectSettings]);

    const handleSuppressSubcontractorCommentsChange = useCallback((value: boolean) => {
        updateProjectSettings({
            documentGenerationConfiguration: {
                ...newProjectSettings.documentGenerationConfiguration,
                shouldSuppressSubcontractorComments: value,
            },
        });
    }, [newProjectSettings, updateProjectSettings]);

    return [
        handleSuppressCommentsChange,
        handleSuppressSubcontractorCommentsChange
    ];
};

export default useDocumentGenerationCommentsSetting;
